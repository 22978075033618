export const eventPath = {
  hyp: [
    "/addplaces",
    "/aboutplace",
    "/location",
    "/photosandvideos",
    "/addservices",
    "/personaldetails",
    "/companydetails",
    "/termsandconditions",
    "/discounts",
    "/calender",
  ],
  psb: [
    "/addplaces",
    "/personalinfo",
    "/photosandvideos",
    "/addequipments",
    "/othercost",
    "/companydetails",
    "/termsandconditions",
    "/discounts",
    "/calender",
  ],
  gsb: [
    "/addplaces",
    "/personalinfo",
    "/photosandvideos",
    "/additem",
    "/addequipments",
    "/othercost",
    "/companydetails",
    "/termsandconditions",
    "/discounts",
    "/calender",
  ],
};
