import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { setLanguage, useLanguage } from "../CommonSlice/languageSlice";

const LanguagePopup = ({ handleClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const language = useLanguage();
  const [selectLanguage, setSelectLanguage] = useState(
    language ? language : "en",
  );
  const onSelectLanguage = (lg) => {
    dispatch(setLanguage(lg));
    setSelectLanguage(lg);
  };

  return (
    <div className="fixed inset-0 w-full h-full bg-[rgba(0,0,0,0.6)] flex z-50">
      <div
        className="w-full lg:w-1/2 2xl:w-7/12"
        onClick={() => handleClose(false)}
      ></div>
      <div className="w-full lg:w-1/2 2xl:w-5/12 bg-brightGray py-12 space-y-8">
        <h1 className="px-12">Language</h1>
        <div className="space-y-2.5 px-12 h-[calc(100vh-170px)] overflow-y-auto language">
          <label
            htmlFor="selact1"
            className="bg-white rounded-md py-3 px-5 flex items-center justify-between cursor-pointer"
          >
            <div className="flex items-start space-x-4">
              <svg
                width="39"
                height="40"
                viewBox="0 0 39 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.24792e-05 15.6322H39V7.58617C39 7.20531 38.6989 6.89648 38.3276 6.89648H0.67244C0.301102 6.89648 0 7.20531 0 7.58617L7.24792e-05 15.6322Z"
                  fill="#FAB446"
                />
                <path
                  d="M0.67252 33.1038H38.3276C38.6989 33.1038 39 32.7949 39 32.4141V24.3682H7.62939e-05V32.4141C7.62939e-05 32.795 0.301182 33.1038 0.67252 33.1038Z"
                  fill="#73AF00"
                />
                <path
                  d="M7.62939e-05 15.6318H39V24.367H7.62939e-05V15.6318Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M19.5001 23.7067C21.493 23.7067 23.1143 22.0439 23.1143 19.9998C23.1143 17.9558 21.493 16.293 19.5001 16.293C17.5071 16.293 15.8859 17.9558 15.8859 19.9998C15.8859 22.0439 17.5071 23.7067 19.5001 23.7067ZM19.5001 16.9826C21.1224 16.9826 22.4419 18.3359 22.4419 19.9998C22.4419 21.6637 21.1224 23.0171 19.5001 23.0171C17.8778 23.0171 16.5582 21.6637 16.5582 19.9998C16.5582 18.3359 17.8778 16.9826 19.5001 16.9826Z"
                  fill="#41479B"
                />
                <path
                  d="M19.4999 20.4206C19.2737 20.4206 19.0902 20.2324 19.0902 20.0003C19.0902 19.7682 19.2737 19.5801 19.4999 19.5801C19.7262 19.5801 19.9097 19.7682 19.9097 20.0003C19.9097 20.2324 19.7262 20.4206 19.4999 20.4206Z"
                  fill="#41479B"
                />
                <path
                  d="M19.4999 20.063L20.5411 20.1708L22.7778 20.063V19.9369L20.5411 19.8291L19.4999 19.9369V20.063Z"
                  fill="#41479B"
                />
                <path
                  d="M19.5 20.063L18.4587 20.1708L16.222 20.063V19.9369L18.4587 19.8291L19.5 19.9369V20.063Z"
                  fill="#41479B"
                />
                <path
                  d="M19.5614 19.9997L19.6665 18.9318L19.5614 16.6377H19.4384L19.3333 18.9318L19.4384 19.9997H19.5614Z"
                  fill="#41479B"
                />
                <path
                  d="M19.5614 20L19.6665 21.068L19.5614 23.362H19.4384L19.3333 21.068L19.4384 20H19.5614Z"
                  fill="#41479B"
                />
                <path
                  d="M19.5434 20.0445L20.354 19.3657L21.8613 17.6673L21.7744 17.5781L20.1184 19.1241L19.4566 19.9555L19.5434 20.0445Z"
                  fill="#41479B"
                />
                <path
                  d="M19.5435 20.0441L18.8816 20.8755L17.2256 22.4215L17.1387 22.3323L18.646 20.6339L19.4566 19.9551L19.5435 20.0441Z"
                  fill="#41479B"
                />
                <path
                  d="M19.5435 19.9555L18.8816 19.1241L17.2256 17.5781L17.1387 17.6673L18.646 19.3657L19.4566 20.0445L19.5435 19.9555Z"
                  fill="#41479B"
                />
                <path
                  d="M19.5434 19.9551L20.354 20.6339L21.8613 22.3323L21.7744 22.4215L20.1184 20.8755L19.4566 20.0441L19.5434 19.9551Z"
                  fill="#41479B"
                />
                <path
                  d="M19.4767 20.058L20.3986 20.5662L22.5052 21.3445L22.5522 21.228L20.526 20.2505L19.5238 19.9414L19.4767 20.058Z"
                  fill="#41479B"
                />
                <path
                  d="M19.4764 20.0584L18.4742 19.7493L16.4479 18.7718L16.495 18.6553L18.6016 19.4336L19.5234 19.9418L19.4764 20.0584Z"
                  fill="#41479B"
                />
                <path
                  d="M19.5566 20.0235L20.0522 19.078L20.811 16.9174L20.6975 16.8691L19.7444 18.9473L19.443 19.9752L19.5566 20.0235Z"
                  fill="#41479B"
                />
                <path
                  d="M19.5571 20.0239L19.2557 21.0518L18.3027 23.1299L18.1891 23.0817L18.9479 20.9211L19.4435 19.9756L19.5571 20.0239Z"
                  fill="#41479B"
                />
                <path
                  d="M19.5238 20.0584L20.526 19.7493L22.5522 18.7718L22.5052 18.6553L20.3986 19.4336L19.4767 19.9418L19.5238 20.0584Z"
                  fill="#41479B"
                />
                <path
                  d="M19.5234 20.058L18.6016 20.5662L16.495 21.3445L16.4479 21.228L18.4742 20.2505L19.4764 19.9414L19.5234 20.058Z"
                  fill="#41479B"
                />
                <path
                  d="M19.5571 19.9752L19.2557 18.9473L18.3027 16.8691L18.1891 16.9174L18.9479 19.078L19.4435 20.0235L19.5571 19.9752Z"
                  fill="#41479B"
                />
                <path
                  d="M19.5566 19.9756L20.0522 20.9211L20.811 23.0817L20.6975 23.1299L19.7444 21.0518L19.443 20.0239L19.5566 19.9756Z"
                  fill="#41479B"
                />
                <path
                  d="M19.5 20.5673C19.1948 20.5673 18.9473 20.3135 18.9473 20.0005C18.9473 19.6874 19.1948 19.4336 19.5 19.4336C19.8053 19.4336 20.0527 19.6874 20.0527 20.0005C20.0527 20.3135 19.8053 20.5673 19.5 20.5673Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M19.5001 20.34C19.3171 20.34 19.1687 20.1878 19.1687 20.0001C19.1687 19.8123 19.3171 19.6602 19.5001 19.6602C19.6832 19.6602 19.8315 19.8123 19.8315 20.0001C19.8315 20.1878 19.6832 20.34 19.5001 20.34Z"
                  fill="#41479B"
                />
              </svg>
              <div>
                <h3 className="text-sm">India - Hindi</h3>
                <span className="input-titel text-spiroDiscoBall">
                  Indian Rupee
                </span>
              </div>
            </div>
            <div>
              <input
                type="radio"
                name="select"
                id="selact1"
                className="w-6 h-6 rounded-full bg-brightGray appearance-none hidden"
                onChange={() => {
                  onSelectLanguage("hi");
                }}
                checked={selectLanguage === "hi"}
              />
              <div className="w-6 h-6 flex items-center justify-center rounded-full bg-brightGray overflow-hidden check-language">
                <i className="icon-right text-[10px] scale-0 origin-center duration-300 transition-all"></i>
              </div>
            </div>
          </label>
          {/* <label
                        htmlFor="selact2"
                        className="bg-white rounded-md py-3 px-5 flex items-center justify-between cursor-pointer"
                    >
                        <div className="flex items-start space-x-4">
                            <svg
                                width="39"
                                height="40"
                                viewBox="0 0 39 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7.24792e-05 15.6322H39V7.58617C39 7.20531 38.6989 6.89648 38.3276 6.89648H0.67244C0.301102 6.89648 0 7.20531 0 7.58617L7.24792e-05 15.6322Z"
                                    fill="#FAB446"
                                />
                                <path
                                    d="M0.67252 33.1038H38.3276C38.6989 33.1038 39 32.7949 39 32.4141V24.3682H7.62939e-05V32.4141C7.62939e-05 32.795 0.301182 33.1038 0.67252 33.1038Z"
                                    fill="#73AF00"
                                />
                                <path
                                    d="M7.62939e-05 15.6318H39V24.367H7.62939e-05V15.6318Z"
                                    fill="#F5F5F5"
                                />
                                <path
                                    d="M19.5001 23.7067C21.493 23.7067 23.1143 22.0439 23.1143 19.9998C23.1143 17.9558 21.493 16.293 19.5001 16.293C17.5071 16.293 15.8859 17.9558 15.8859 19.9998C15.8859 22.0439 17.5071 23.7067 19.5001 23.7067ZM19.5001 16.9826C21.1224 16.9826 22.4419 18.3359 22.4419 19.9998C22.4419 21.6637 21.1224 23.0171 19.5001 23.0171C17.8778 23.0171 16.5582 21.6637 16.5582 19.9998C16.5582 18.3359 17.8778 16.9826 19.5001 16.9826Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4999 20.4206C19.2737 20.4206 19.0902 20.2324 19.0902 20.0003C19.0902 19.7682 19.2737 19.5801 19.4999 19.5801C19.7262 19.5801 19.9097 19.7682 19.9097 20.0003C19.9097 20.2324 19.7262 20.4206 19.4999 20.4206Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4999 20.063L20.5411 20.1708L22.7778 20.063V19.9369L20.5411 19.8291L19.4999 19.9369V20.063Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5 20.063L18.4587 20.1708L16.222 20.063V19.9369L18.4587 19.8291L19.5 19.9369V20.063Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5614 19.9997L19.6665 18.9318L19.5614 16.6377H19.4384L19.3333 18.9318L19.4384 19.9997H19.5614Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5614 20L19.6665 21.068L19.5614 23.362H19.4384L19.3333 21.068L19.4384 20H19.5614Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5434 20.0445L20.354 19.3657L21.8613 17.6673L21.7744 17.5781L20.1184 19.1241L19.4566 19.9555L19.5434 20.0445Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5435 20.0441L18.8816 20.8755L17.2256 22.4215L17.1387 22.3323L18.646 20.6339L19.4566 19.9551L19.5435 20.0441Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5435 19.9555L18.8816 19.1241L17.2256 17.5781L17.1387 17.6673L18.646 19.3657L19.4566 20.0445L19.5435 19.9555Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5434 19.9551L20.354 20.6339L21.8613 22.3323L21.7744 22.4215L20.1184 20.8755L19.4566 20.0441L19.5434 19.9551Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4767 20.058L20.3986 20.5662L22.5052 21.3445L22.5522 21.228L20.526 20.2505L19.5238 19.9414L19.4767 20.058Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4764 20.0584L18.4742 19.7493L16.4479 18.7718L16.495 18.6553L18.6016 19.4336L19.5234 19.9418L19.4764 20.0584Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5566 20.0235L20.0522 19.078L20.811 16.9174L20.6975 16.8691L19.7444 18.9473L19.443 19.9752L19.5566 20.0235Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5571 20.0239L19.2557 21.0518L18.3027 23.1299L18.1891 23.0817L18.9479 20.9211L19.4435 19.9756L19.5571 20.0239Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5238 20.0584L20.526 19.7493L22.5522 18.7718L22.5052 18.6553L20.3986 19.4336L19.4767 19.9418L19.5238 20.0584Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5234 20.058L18.6016 20.5662L16.495 21.3445L16.4479 21.228L18.4742 20.2505L19.4764 19.9414L19.5234 20.058Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5571 19.9752L19.2557 18.9473L18.3027 16.8691L18.1891 16.9174L18.9479 19.078L19.4435 20.0235L19.5571 19.9752Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5566 19.9756L20.0522 20.9211L20.811 23.0817L20.6975 23.1299L19.7444 21.0518L19.443 20.0239L19.5566 19.9756Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5 20.5673C19.1948 20.5673 18.9473 20.3135 18.9473 20.0005C18.9473 19.6874 19.1948 19.4336 19.5 19.4336C19.8053 19.4336 20.0527 19.6874 20.0527 20.0005C20.0527 20.3135 19.8053 20.5673 19.5 20.5673Z"
                                    fill="#F5F5F5"
                                />
                                <path
                                    d="M19.5001 20.34C19.3171 20.34 19.1687 20.1878 19.1687 20.0001C19.1687 19.8123 19.3171 19.6602 19.5001 19.6602C19.6832 19.6602 19.8315 19.8123 19.8315 20.0001C19.8315 20.1878 19.6832 20.34 19.5001 20.34Z"
                                    fill="#41479B"
                                />
                            </svg>
                            <div>
                                <h3 className="text-sm">India - English</h3>
                                <span className="input-titel text-spiroDiscoBall">
                                    Indian Rupee
                                </span>
                            </div>
                        </div>
                        <div>
                            <input
                                type="radio"
                                name="select"
                                id="selact2"
                                className="w-6 h-6 rounded-full bg-brightGray appearance-none hidden"
                            />
                            <div className="w-6 h-6 flex items-center justify-center rounded-full bg-brightGray overflow-hidden check-language">
                                <i className="icon-right text-[10px] scale-0 origin-center duration-300 transition-all"></i>
                            </div>
                        </div>
                    </label>
                    <label
                        htmlFor="selact3"
                        className="bg-white rounded-md py-3 px-5 flex items-center justify-between cursor-pointer"
                    >
                        <div className="flex items-start space-x-4">
                            <svg
                                width="39"
                                height="40"
                                viewBox="0 0 39 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7.24792e-05 15.6322H39V7.58617C39 7.20531 38.6989 6.89648 38.3276 6.89648H0.67244C0.301102 6.89648 0 7.20531 0 7.58617L7.24792e-05 15.6322Z"
                                    fill="#FAB446"
                                />
                                <path
                                    d="M0.67252 33.1038H38.3276C38.6989 33.1038 39 32.7949 39 32.4141V24.3682H7.62939e-05V32.4141C7.62939e-05 32.795 0.301182 33.1038 0.67252 33.1038Z"
                                    fill="#73AF00"
                                />
                                <path
                                    d="M7.62939e-05 15.6318H39V24.367H7.62939e-05V15.6318Z"
                                    fill="#F5F5F5"
                                />
                                <path
                                    d="M19.5001 23.7067C21.493 23.7067 23.1143 22.0439 23.1143 19.9998C23.1143 17.9558 21.493 16.293 19.5001 16.293C17.5071 16.293 15.8859 17.9558 15.8859 19.9998C15.8859 22.0439 17.5071 23.7067 19.5001 23.7067ZM19.5001 16.9826C21.1224 16.9826 22.4419 18.3359 22.4419 19.9998C22.4419 21.6637 21.1224 23.0171 19.5001 23.0171C17.8778 23.0171 16.5582 21.6637 16.5582 19.9998C16.5582 18.3359 17.8778 16.9826 19.5001 16.9826Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4999 20.4206C19.2737 20.4206 19.0902 20.2324 19.0902 20.0003C19.0902 19.7682 19.2737 19.5801 19.4999 19.5801C19.7262 19.5801 19.9097 19.7682 19.9097 20.0003C19.9097 20.2324 19.7262 20.4206 19.4999 20.4206Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4999 20.063L20.5411 20.1708L22.7778 20.063V19.9369L20.5411 19.8291L19.4999 19.9369V20.063Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5 20.063L18.4587 20.1708L16.222 20.063V19.9369L18.4587 19.8291L19.5 19.9369V20.063Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5614 19.9997L19.6665 18.9318L19.5614 16.6377H19.4384L19.3333 18.9318L19.4384 19.9997H19.5614Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5614 20L19.6665 21.068L19.5614 23.362H19.4384L19.3333 21.068L19.4384 20H19.5614Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5434 20.0445L20.354 19.3657L21.8613 17.6673L21.7744 17.5781L20.1184 19.1241L19.4566 19.9555L19.5434 20.0445Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5435 20.0441L18.8816 20.8755L17.2256 22.4215L17.1387 22.3323L18.646 20.6339L19.4566 19.9551L19.5435 20.0441Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5435 19.9555L18.8816 19.1241L17.2256 17.5781L17.1387 17.6673L18.646 19.3657L19.4566 20.0445L19.5435 19.9555Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5434 19.9551L20.354 20.6339L21.8613 22.3323L21.7744 22.4215L20.1184 20.8755L19.4566 20.0441L19.5434 19.9551Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4767 20.058L20.3986 20.5662L22.5052 21.3445L22.5522 21.228L20.526 20.2505L19.5238 19.9414L19.4767 20.058Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4764 20.0584L18.4742 19.7493L16.4479 18.7718L16.495 18.6553L18.6016 19.4336L19.5234 19.9418L19.4764 20.0584Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5566 20.0235L20.0522 19.078L20.811 16.9174L20.6975 16.8691L19.7444 18.9473L19.443 19.9752L19.5566 20.0235Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5571 20.0239L19.2557 21.0518L18.3027 23.1299L18.1891 23.0817L18.9479 20.9211L19.4435 19.9756L19.5571 20.0239Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5238 20.0584L20.526 19.7493L22.5522 18.7718L22.5052 18.6553L20.3986 19.4336L19.4767 19.9418L19.5238 20.0584Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5234 20.058L18.6016 20.5662L16.495 21.3445L16.4479 21.228L18.4742 20.2505L19.4764 19.9414L19.5234 20.058Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5571 19.9752L19.2557 18.9473L18.3027 16.8691L18.1891 16.9174L18.9479 19.078L19.4435 20.0235L19.5571 19.9752Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5566 19.9756L20.0522 20.9211L20.811 23.0817L20.6975 23.1299L19.7444 21.0518L19.443 20.0239L19.5566 19.9756Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5 20.5673C19.1948 20.5673 18.9473 20.3135 18.9473 20.0005C18.9473 19.6874 19.1948 19.4336 19.5 19.4336C19.8053 19.4336 20.0527 19.6874 20.0527 20.0005C20.0527 20.3135 19.8053 20.5673 19.5 20.5673Z"
                                    fill="#F5F5F5"
                                />
                                <path
                                    d="M19.5001 20.34C19.3171 20.34 19.1687 20.1878 19.1687 20.0001C19.1687 19.8123 19.3171 19.6602 19.5001 19.6602C19.6832 19.6602 19.8315 19.8123 19.8315 20.0001C19.8315 20.1878 19.6832 20.34 19.5001 20.34Z"
                                    fill="#41479B"
                                />
                            </svg>
                            <div>
                                <h3 className="text-sm">India - Bengali</h3>
                                <span className="input-titel text-spiroDiscoBall">
                                    Indian Rupee
                                </span>
                            </div>
                        </div>
                        <div>
                            <input
                                type="radio"
                                name="select"
                                id="selact3"
                                className="w-6 h-6 rounded-full bg-brightGray appearance-none hidden"
                            />
                            <div className="w-6 h-6 flex items-center justify-center rounded-full bg-brightGray overflow-hidden check-language">
                                <i className="icon-right text-[10px] scale-0 origin-center duration-300 transition-all"></i>
                            </div>
                        </div>
                    </label>
                    <label
                        htmlFor="selact4"
                        className="bg-white rounded-md py-3 px-5 flex items-center justify-between cursor-pointer"
                    >
                        <div className="flex items-start space-x-4">
                            <svg
                                width="39"
                                height="40"
                                viewBox="0 0 39 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7.24792e-05 15.6322H39V7.58617C39 7.20531 38.6989 6.89648 38.3276 6.89648H0.67244C0.301102 6.89648 0 7.20531 0 7.58617L7.24792e-05 15.6322Z"
                                    fill="#FAB446"
                                />
                                <path
                                    d="M0.67252 33.1038H38.3276C38.6989 33.1038 39 32.7949 39 32.4141V24.3682H7.62939e-05V32.4141C7.62939e-05 32.795 0.301182 33.1038 0.67252 33.1038Z"
                                    fill="#73AF00"
                                />
                                <path
                                    d="M7.62939e-05 15.6318H39V24.367H7.62939e-05V15.6318Z"
                                    fill="#F5F5F5"
                                />
                                <path
                                    d="M19.5001 23.7067C21.493 23.7067 23.1143 22.0439 23.1143 19.9998C23.1143 17.9558 21.493 16.293 19.5001 16.293C17.5071 16.293 15.8859 17.9558 15.8859 19.9998C15.8859 22.0439 17.5071 23.7067 19.5001 23.7067ZM19.5001 16.9826C21.1224 16.9826 22.4419 18.3359 22.4419 19.9998C22.4419 21.6637 21.1224 23.0171 19.5001 23.0171C17.8778 23.0171 16.5582 21.6637 16.5582 19.9998C16.5582 18.3359 17.8778 16.9826 19.5001 16.9826Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4999 20.4206C19.2737 20.4206 19.0902 20.2324 19.0902 20.0003C19.0902 19.7682 19.2737 19.5801 19.4999 19.5801C19.7262 19.5801 19.9097 19.7682 19.9097 20.0003C19.9097 20.2324 19.7262 20.4206 19.4999 20.4206Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4999 20.063L20.5411 20.1708L22.7778 20.063V19.9369L20.5411 19.8291L19.4999 19.9369V20.063Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5 20.063L18.4587 20.1708L16.222 20.063V19.9369L18.4587 19.8291L19.5 19.9369V20.063Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5614 19.9997L19.6665 18.9318L19.5614 16.6377H19.4384L19.3333 18.9318L19.4384 19.9997H19.5614Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5614 20L19.6665 21.068L19.5614 23.362H19.4384L19.3333 21.068L19.4384 20H19.5614Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5434 20.0445L20.354 19.3657L21.8613 17.6673L21.7744 17.5781L20.1184 19.1241L19.4566 19.9555L19.5434 20.0445Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5435 20.0441L18.8816 20.8755L17.2256 22.4215L17.1387 22.3323L18.646 20.6339L19.4566 19.9551L19.5435 20.0441Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5435 19.9555L18.8816 19.1241L17.2256 17.5781L17.1387 17.6673L18.646 19.3657L19.4566 20.0445L19.5435 19.9555Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5434 19.9551L20.354 20.6339L21.8613 22.3323L21.7744 22.4215L20.1184 20.8755L19.4566 20.0441L19.5434 19.9551Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4767 20.058L20.3986 20.5662L22.5052 21.3445L22.5522 21.228L20.526 20.2505L19.5238 19.9414L19.4767 20.058Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4764 20.0584L18.4742 19.7493L16.4479 18.7718L16.495 18.6553L18.6016 19.4336L19.5234 19.9418L19.4764 20.0584Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5566 20.0235L20.0522 19.078L20.811 16.9174L20.6975 16.8691L19.7444 18.9473L19.443 19.9752L19.5566 20.0235Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5571 20.0239L19.2557 21.0518L18.3027 23.1299L18.1891 23.0817L18.9479 20.9211L19.4435 19.9756L19.5571 20.0239Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5238 20.0584L20.526 19.7493L22.5522 18.7718L22.5052 18.6553L20.3986 19.4336L19.4767 19.9418L19.5238 20.0584Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5234 20.058L18.6016 20.5662L16.495 21.3445L16.4479 21.228L18.4742 20.2505L19.4764 19.9414L19.5234 20.058Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5571 19.9752L19.2557 18.9473L18.3027 16.8691L18.1891 16.9174L18.9479 19.078L19.4435 20.0235L19.5571 19.9752Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5566 19.9756L20.0522 20.9211L20.811 23.0817L20.6975 23.1299L19.7444 21.0518L19.443 20.0239L19.5566 19.9756Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5 20.5673C19.1948 20.5673 18.9473 20.3135 18.9473 20.0005C18.9473 19.6874 19.1948 19.4336 19.5 19.4336C19.8053 19.4336 20.0527 19.6874 20.0527 20.0005C20.0527 20.3135 19.8053 20.5673 19.5 20.5673Z"
                                    fill="#F5F5F5"
                                />
                                <path
                                    d="M19.5001 20.34C19.3171 20.34 19.1687 20.1878 19.1687 20.0001C19.1687 19.8123 19.3171 19.6602 19.5001 19.6602C19.6832 19.6602 19.8315 19.8123 19.8315 20.0001C19.8315 20.1878 19.6832 20.34 19.5001 20.34Z"
                                    fill="#41479B"
                                />
                            </svg>
                            <div>
                                <h3 className="text-sm">India - Marathi</h3>
                                <span className="input-titel text-spiroDiscoBall">
                                    Indian Rupee
                                </span>
                            </div>
                        </div>
                        <div>
                            <input
                                type="radio"
                                name="select"
                                id="selact4"
                                className="w-6 h-6 rounded-full bg-brightGray appearance-none hidden"
                            />
                            <div className="w-6 h-6 flex items-center justify-center rounded-full bg-brightGray overflow-hidden check-language">
                                <i className="icon-right text-[10px] scale-0 origin-center duration-300 transition-all"></i>
                            </div>
                        </div>
                    </label>
                    <label
                        htmlFor="selact5"
                        className="bg-white rounded-md py-3 px-5 flex items-center justify-between cursor-pointer"
                    >
                        <div className="flex items-start space-x-4">
                            <svg
                                width="39"
                                height="40"
                                viewBox="0 0 39 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7.24792e-05 15.6322H39V7.58617C39 7.20531 38.6989 6.89648 38.3276 6.89648H0.67244C0.301102 6.89648 0 7.20531 0 7.58617L7.24792e-05 15.6322Z"
                                    fill="#FAB446"
                                />
                                <path
                                    d="M0.67252 33.1038H38.3276C38.6989 33.1038 39 32.7949 39 32.4141V24.3682H7.62939e-05V32.4141C7.62939e-05 32.795 0.301182 33.1038 0.67252 33.1038Z"
                                    fill="#73AF00"
                                />
                                <path
                                    d="M7.62939e-05 15.6318H39V24.367H7.62939e-05V15.6318Z"
                                    fill="#F5F5F5"
                                />
                                <path
                                    d="M19.5001 23.7067C21.493 23.7067 23.1143 22.0439 23.1143 19.9998C23.1143 17.9558 21.493 16.293 19.5001 16.293C17.5071 16.293 15.8859 17.9558 15.8859 19.9998C15.8859 22.0439 17.5071 23.7067 19.5001 23.7067ZM19.5001 16.9826C21.1224 16.9826 22.4419 18.3359 22.4419 19.9998C22.4419 21.6637 21.1224 23.0171 19.5001 23.0171C17.8778 23.0171 16.5582 21.6637 16.5582 19.9998C16.5582 18.3359 17.8778 16.9826 19.5001 16.9826Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4999 20.4206C19.2737 20.4206 19.0902 20.2324 19.0902 20.0003C19.0902 19.7682 19.2737 19.5801 19.4999 19.5801C19.7262 19.5801 19.9097 19.7682 19.9097 20.0003C19.9097 20.2324 19.7262 20.4206 19.4999 20.4206Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4999 20.063L20.5411 20.1708L22.7778 20.063V19.9369L20.5411 19.8291L19.4999 19.9369V20.063Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5 20.063L18.4587 20.1708L16.222 20.063V19.9369L18.4587 19.8291L19.5 19.9369V20.063Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5614 19.9997L19.6665 18.9318L19.5614 16.6377H19.4384L19.3333 18.9318L19.4384 19.9997H19.5614Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5614 20L19.6665 21.068L19.5614 23.362H19.4384L19.3333 21.068L19.4384 20H19.5614Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5434 20.0445L20.354 19.3657L21.8613 17.6673L21.7744 17.5781L20.1184 19.1241L19.4566 19.9555L19.5434 20.0445Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5435 20.0441L18.8816 20.8755L17.2256 22.4215L17.1387 22.3323L18.646 20.6339L19.4566 19.9551L19.5435 20.0441Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5435 19.9555L18.8816 19.1241L17.2256 17.5781L17.1387 17.6673L18.646 19.3657L19.4566 20.0445L19.5435 19.9555Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5434 19.9551L20.354 20.6339L21.8613 22.3323L21.7744 22.4215L20.1184 20.8755L19.4566 20.0441L19.5434 19.9551Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4767 20.058L20.3986 20.5662L22.5052 21.3445L22.5522 21.228L20.526 20.2505L19.5238 19.9414L19.4767 20.058Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4764 20.0584L18.4742 19.7493L16.4479 18.7718L16.495 18.6553L18.6016 19.4336L19.5234 19.9418L19.4764 20.0584Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5566 20.0235L20.0522 19.078L20.811 16.9174L20.6975 16.8691L19.7444 18.9473L19.443 19.9752L19.5566 20.0235Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5571 20.0239L19.2557 21.0518L18.3027 23.1299L18.1891 23.0817L18.9479 20.9211L19.4435 19.9756L19.5571 20.0239Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5238 20.0584L20.526 19.7493L22.5522 18.7718L22.5052 18.6553L20.3986 19.4336L19.4767 19.9418L19.5238 20.0584Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5234 20.058L18.6016 20.5662L16.495 21.3445L16.4479 21.228L18.4742 20.2505L19.4764 19.9414L19.5234 20.058Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5571 19.9752L19.2557 18.9473L18.3027 16.8691L18.1891 16.9174L18.9479 19.078L19.4435 20.0235L19.5571 19.9752Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5566 19.9756L20.0522 20.9211L20.811 23.0817L20.6975 23.1299L19.7444 21.0518L19.443 20.0239L19.5566 19.9756Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5 20.5673C19.1948 20.5673 18.9473 20.3135 18.9473 20.0005C18.9473 19.6874 19.1948 19.4336 19.5 19.4336C19.8053 19.4336 20.0527 19.6874 20.0527 20.0005C20.0527 20.3135 19.8053 20.5673 19.5 20.5673Z"
                                    fill="#F5F5F5"
                                />
                                <path
                                    d="M19.5001 20.34C19.3171 20.34 19.1687 20.1878 19.1687 20.0001C19.1687 19.8123 19.3171 19.6602 19.5001 19.6602C19.6832 19.6602 19.8315 19.8123 19.8315 20.0001C19.8315 20.1878 19.6832 20.34 19.5001 20.34Z"
                                    fill="#41479B"
                                />
                            </svg>
                            <div>
                                <h3 className="text-sm">India - Punjabi</h3>
                                <span className="input-titel text-spiroDiscoBall">
                                    Indian Rupee
                                </span>
                            </div>
                        </div>
                        <div>
                            <input
                                type="radio"
                                name="select"
                                id="selact5"
                                className="w-6 h-6 rounded-full bg-brightGray appearance-none hidden"
                            />
                            <div className="w-6 h-6 flex items-center justify-center rounded-full bg-brightGray overflow-hidden check-language">
                                <i className="icon-right text-[10px] scale-0 origin-center duration-300 transition-all"></i>
                            </div>
                        </div>
                    </label>
                    <label
                        htmlFor="selact6"
                        className="bg-white rounded-md py-3 px-5 flex items-center justify-between cursor-pointer"
                    >
                        <div className="flex items-start space-x-4">
                            <svg
                                width="39"
                                height="40"
                                viewBox="0 0 39 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7.24792e-05 15.6322H39V7.58617C39 7.20531 38.6989 6.89648 38.3276 6.89648H0.67244C0.301102 6.89648 0 7.20531 0 7.58617L7.24792e-05 15.6322Z"
                                    fill="#FAB446"
                                />
                                <path
                                    d="M0.67252 33.1038H38.3276C38.6989 33.1038 39 32.7949 39 32.4141V24.3682H7.62939e-05V32.4141C7.62939e-05 32.795 0.301182 33.1038 0.67252 33.1038Z"
                                    fill="#73AF00"
                                />
                                <path
                                    d="M7.62939e-05 15.6318H39V24.367H7.62939e-05V15.6318Z"
                                    fill="#F5F5F5"
                                />
                                <path
                                    d="M19.5001 23.7067C21.493 23.7067 23.1143 22.0439 23.1143 19.9998C23.1143 17.9558 21.493 16.293 19.5001 16.293C17.5071 16.293 15.8859 17.9558 15.8859 19.9998C15.8859 22.0439 17.5071 23.7067 19.5001 23.7067ZM19.5001 16.9826C21.1224 16.9826 22.4419 18.3359 22.4419 19.9998C22.4419 21.6637 21.1224 23.0171 19.5001 23.0171C17.8778 23.0171 16.5582 21.6637 16.5582 19.9998C16.5582 18.3359 17.8778 16.9826 19.5001 16.9826Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4999 20.4206C19.2737 20.4206 19.0902 20.2324 19.0902 20.0003C19.0902 19.7682 19.2737 19.5801 19.4999 19.5801C19.7262 19.5801 19.9097 19.7682 19.9097 20.0003C19.9097 20.2324 19.7262 20.4206 19.4999 20.4206Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4999 20.063L20.5411 20.1708L22.7778 20.063V19.9369L20.5411 19.8291L19.4999 19.9369V20.063Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5 20.063L18.4587 20.1708L16.222 20.063V19.9369L18.4587 19.8291L19.5 19.9369V20.063Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5614 19.9997L19.6665 18.9318L19.5614 16.6377H19.4384L19.3333 18.9318L19.4384 19.9997H19.5614Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5614 20L19.6665 21.068L19.5614 23.362H19.4384L19.3333 21.068L19.4384 20H19.5614Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5434 20.0445L20.354 19.3657L21.8613 17.6673L21.7744 17.5781L20.1184 19.1241L19.4566 19.9555L19.5434 20.0445Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5435 20.0441L18.8816 20.8755L17.2256 22.4215L17.1387 22.3323L18.646 20.6339L19.4566 19.9551L19.5435 20.0441Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5435 19.9555L18.8816 19.1241L17.2256 17.5781L17.1387 17.6673L18.646 19.3657L19.4566 20.0445L19.5435 19.9555Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5434 19.9551L20.354 20.6339L21.8613 22.3323L21.7744 22.4215L20.1184 20.8755L19.4566 20.0441L19.5434 19.9551Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4767 20.058L20.3986 20.5662L22.5052 21.3445L22.5522 21.228L20.526 20.2505L19.5238 19.9414L19.4767 20.058Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4764 20.0584L18.4742 19.7493L16.4479 18.7718L16.495 18.6553L18.6016 19.4336L19.5234 19.9418L19.4764 20.0584Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5566 20.0235L20.0522 19.078L20.811 16.9174L20.6975 16.8691L19.7444 18.9473L19.443 19.9752L19.5566 20.0235Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5571 20.0239L19.2557 21.0518L18.3027 23.1299L18.1891 23.0817L18.9479 20.9211L19.4435 19.9756L19.5571 20.0239Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5238 20.0584L20.526 19.7493L22.5522 18.7718L22.5052 18.6553L20.3986 19.4336L19.4767 19.9418L19.5238 20.0584Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5234 20.058L18.6016 20.5662L16.495 21.3445L16.4479 21.228L18.4742 20.2505L19.4764 19.9414L19.5234 20.058Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5571 19.9752L19.2557 18.9473L18.3027 16.8691L18.1891 16.9174L18.9479 19.078L19.4435 20.0235L19.5571 19.9752Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5566 19.9756L20.0522 20.9211L20.811 23.0817L20.6975 23.1299L19.7444 21.0518L19.443 20.0239L19.5566 19.9756Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5 20.5673C19.1948 20.5673 18.9473 20.3135 18.9473 20.0005C18.9473 19.6874 19.1948 19.4336 19.5 19.4336C19.8053 19.4336 20.0527 19.6874 20.0527 20.0005C20.0527 20.3135 19.8053 20.5673 19.5 20.5673Z"
                                    fill="#F5F5F5"
                                />
                                <path
                                    d="M19.5001 20.34C19.3171 20.34 19.1687 20.1878 19.1687 20.0001C19.1687 19.8123 19.3171 19.6602 19.5001 19.6602C19.6832 19.6602 19.8315 19.8123 19.8315 20.0001C19.8315 20.1878 19.6832 20.34 19.5001 20.34Z"
                                    fill="#41479B"
                                />
                            </svg>
                            <div>
                                <h3 className="text-sm">India - Tamil</h3>
                                <span className="input-titel text-spiroDiscoBall">
                                    Indian Rupee
                                </span>
                            </div>
                        </div>
                        <div>
                            <input
                                type="radio"
                                name="select"
                                id="selact6"
                                className="w-6 h-6 rounded-full bg-brightGray appearance-none hidden"
                            />
                            <div className="w-6 h-6 flex items-center justify-center rounded-full bg-brightGray overflow-hidden check-language">
                                <i className="icon-right text-[10px] scale-0 origin-center duration-300 transition-all"></i>
                            </div>
                        </div>
                    </label>
                    <label
                        htmlFor="selact7"
                        className="bg-white rounded-md py-3 px-5 flex items-center justify-between cursor-pointer"
                    >
                        <div className="flex items-start space-x-4">
                            <svg
                                width="39"
                                height="40"
                                viewBox="0 0 39 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7.24792e-05 15.6322H39V7.58617C39 7.20531 38.6989 6.89648 38.3276 6.89648H0.67244C0.301102 6.89648 0 7.20531 0 7.58617L7.24792e-05 15.6322Z"
                                    fill="#FAB446"
                                />
                                <path
                                    d="M0.67252 33.1038H38.3276C38.6989 33.1038 39 32.7949 39 32.4141V24.3682H7.62939e-05V32.4141C7.62939e-05 32.795 0.301182 33.1038 0.67252 33.1038Z"
                                    fill="#73AF00"
                                />
                                <path
                                    d="M7.62939e-05 15.6318H39V24.367H7.62939e-05V15.6318Z"
                                    fill="#F5F5F5"
                                />
                                <path
                                    d="M19.5001 23.7067C21.493 23.7067 23.1143 22.0439 23.1143 19.9998C23.1143 17.9558 21.493 16.293 19.5001 16.293C17.5071 16.293 15.8859 17.9558 15.8859 19.9998C15.8859 22.0439 17.5071 23.7067 19.5001 23.7067ZM19.5001 16.9826C21.1224 16.9826 22.4419 18.3359 22.4419 19.9998C22.4419 21.6637 21.1224 23.0171 19.5001 23.0171C17.8778 23.0171 16.5582 21.6637 16.5582 19.9998C16.5582 18.3359 17.8778 16.9826 19.5001 16.9826Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4999 20.4206C19.2737 20.4206 19.0902 20.2324 19.0902 20.0003C19.0902 19.7682 19.2737 19.5801 19.4999 19.5801C19.7262 19.5801 19.9097 19.7682 19.9097 20.0003C19.9097 20.2324 19.7262 20.4206 19.4999 20.4206Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4999 20.063L20.5411 20.1708L22.7778 20.063V19.9369L20.5411 19.8291L19.4999 19.9369V20.063Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5 20.063L18.4587 20.1708L16.222 20.063V19.9369L18.4587 19.8291L19.5 19.9369V20.063Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5614 19.9997L19.6665 18.9318L19.5614 16.6377H19.4384L19.3333 18.9318L19.4384 19.9997H19.5614Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5614 20L19.6665 21.068L19.5614 23.362H19.4384L19.3333 21.068L19.4384 20H19.5614Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5434 20.0445L20.354 19.3657L21.8613 17.6673L21.7744 17.5781L20.1184 19.1241L19.4566 19.9555L19.5434 20.0445Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5435 20.0441L18.8816 20.8755L17.2256 22.4215L17.1387 22.3323L18.646 20.6339L19.4566 19.9551L19.5435 20.0441Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5435 19.9555L18.8816 19.1241L17.2256 17.5781L17.1387 17.6673L18.646 19.3657L19.4566 20.0445L19.5435 19.9555Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5434 19.9551L20.354 20.6339L21.8613 22.3323L21.7744 22.4215L20.1184 20.8755L19.4566 20.0441L19.5434 19.9551Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4767 20.058L20.3986 20.5662L22.5052 21.3445L22.5522 21.228L20.526 20.2505L19.5238 19.9414L19.4767 20.058Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.4764 20.0584L18.4742 19.7493L16.4479 18.7718L16.495 18.6553L18.6016 19.4336L19.5234 19.9418L19.4764 20.0584Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5566 20.0235L20.0522 19.078L20.811 16.9174L20.6975 16.8691L19.7444 18.9473L19.443 19.9752L19.5566 20.0235Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5571 20.0239L19.2557 21.0518L18.3027 23.1299L18.1891 23.0817L18.9479 20.9211L19.4435 19.9756L19.5571 20.0239Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5238 20.0584L20.526 19.7493L22.5522 18.7718L22.5052 18.6553L20.3986 19.4336L19.4767 19.9418L19.5238 20.0584Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5234 20.058L18.6016 20.5662L16.495 21.3445L16.4479 21.228L18.4742 20.2505L19.4764 19.9414L19.5234 20.058Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5571 19.9752L19.2557 18.9473L18.3027 16.8691L18.1891 16.9174L18.9479 19.078L19.4435 20.0235L19.5571 19.9752Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5566 19.9756L20.0522 20.9211L20.811 23.0817L20.6975 23.1299L19.7444 21.0518L19.443 20.0239L19.5566 19.9756Z"
                                    fill="#41479B"
                                />
                                <path
                                    d="M19.5 20.5673C19.1948 20.5673 18.9473 20.3135 18.9473 20.0005C18.9473 19.6874 19.1948 19.4336 19.5 19.4336C19.8053 19.4336 20.0527 19.6874 20.0527 20.0005C20.0527 20.3135 19.8053 20.5673 19.5 20.5673Z"
                                    fill="#F5F5F5"
                                />
                                <path
                                    d="M19.5001 20.34C19.3171 20.34 19.1687 20.1878 19.1687 20.0001C19.1687 19.8123 19.3171 19.6602 19.5001 19.6602C19.6832 19.6602 19.8315 19.8123 19.8315 20.0001C19.8315 20.1878 19.6832 20.34 19.5001 20.34Z"
                                    fill="#41479B"
                                />
                            </svg>
                            <div>
                                <h3 className="text-sm">India - Telugu</h3>
                                <span className="input-titel text-spiroDiscoBall">
                                    Indian Rupee
                                </span>
                            </div>
                        </div>
                        <div>
                            <input
                                type="radio"
                                name="select"
                                id="selact7"
                                className="w-6 h-6 rounded-full bg-brightGray appearance-none hidden"
                            />
                            <div className="w-6 h-6 flex items-center justify-center rounded-full bg-brightGray overflow-hidden check-language">
                                <i className="icon-right text-[10px] scale-0 origin-center duration-300 transition-all"></i>
                            </div>
                        </div>
                    </label>*/}
          <label
            htmlFor="selact8"
            className="bg-white rounded-md py-3 px-5 flex items-center justify-between cursor-pointer"
          >
            <div className="flex items-start space-x-4">
              <svg
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.24792e-05 15.2419H39V7.39705C39 7.02572 38.6989 6.72461 38.3276 6.72461H0.67244C0.301102 6.72461 0 7.02572 0 7.39705L7.24792e-05 15.2419Z"
                  fill="#464655"
                />
                <path
                  d="M0.67252 32.276H38.3276C38.6989 32.276 39 31.9749 39 31.6036V23.7588H7.62939e-05V31.6036C7.62939e-05 31.975 0.301182 32.276 0.67252 32.276Z"
                  fill="#FFE15A"
                />
                <path
                  d="M7.62939e-05 15.2412H39V23.758H7.62939e-05V15.2412Z"
                  fill="#FF4B55"
                />
              </svg>
              <div>
                <h3 className="text-sm">Germany - German</h3>
                <span className="input-titel text-spiroDiscoBall">
                  German Mark (DEM)
                </span>
              </div>
            </div>
            <div>
              <input
                type="radio"
                name="select"
                id="selact8"
                className="w-6 h-6 rounded-full bg-brightGray appearance-none hidden"
                onChange={() => {
                  onSelectLanguage("gr");
                }}
                checked={selectLanguage === "gr"}
              />
              <div className="w-6 h-6 flex items-center justify-center rounded-full bg-brightGray overflow-hidden check-language">
                <i className="icon-right text-[10px] scale-0 origin-center duration-300 transition-all"></i>
              </div>
            </div>
          </label>
          <label
            htmlFor="selact9"
            className="bg-white rounded-md py-3 px-5 flex items-center justify-between cursor-pointer"
          >
            <div className="flex items-start space-x-4">
              <svg
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M38.3275 32.2763H0.672444C0.301107 32.2763 0 31.9752 0 31.6039V7.39705C0 7.02572 0.301107 6.72461 0.672444 6.72461H38.3276C38.6989 6.72461 39 7.02572 39 7.39705V31.6039C38.9999 31.9753 38.6988 32.2763 38.3275 32.2763Z"
                  fill="#FF4B55"
                />
                <path
                  d="M6.47516 10.7199L7.11623 12.642L9.14239 12.6577C9.40473 12.6597 9.5135 12.9945 9.30243 13.1503L7.67251 14.3539L8.28363 16.2858C8.3627 16.5359 8.07797 16.7427 7.86454 16.5902L6.21618 15.4119L4.56782 16.5902C4.35439 16.7427 4.06966 16.5358 4.14873 16.2857L4.75985 14.3538L3.12993 13.1503C2.91886 12.9945 3.02763 12.6597 3.28997 12.6577L5.31614 12.642L5.9572 10.7199C6.04022 10.471 6.39221 10.471 6.47516 10.7199Z"
                  fill="#FFE15A"
                />
                <path
                  d="M13.8326 11.1934L14.2923 11.8203L15.0342 11.5883C15.1302 11.5583 15.2092 11.6678 15.1504 11.7494L14.6963 12.3802L15.1461 13.014C15.2044 13.096 15.1247 13.205 15.0288 13.1743L14.2885 12.9373L13.8247 13.5611C13.7647 13.6419 13.6363 13.5997 13.636 13.4991L13.6325 12.7218L12.8959 12.4734C12.8007 12.4412 12.801 12.3062 12.8966 12.2747L13.6347 12.0312L13.6434 11.254C13.6445 11.1535 13.7731 11.1123 13.8326 11.1934Z"
                  fill="#FFE15A"
                />
                <path
                  d="M11.0343 9.32482L11.8071 9.40876L12.1367 8.70486C12.1795 8.61376 12.3135 8.62937 12.3341 8.72786L12.493 9.48867L13.2643 9.58464C13.3642 9.59706 13.3908 9.72945 13.3034 9.77949L12.6289 10.1657L12.776 10.9289C12.795 11.0278 12.6773 11.0939 12.6028 11.0263L12.027 10.5042L11.3466 10.8799C11.2586 10.9285 11.1592 10.837 11.2004 10.7452L11.5191 10.0362L10.9514 9.50512C10.878 9.43672 10.9343 9.31401 11.0343 9.32482Z"
                  fill="#FFE15A"
                />
                <path
                  d="M12.2554 16.8575L11.7958 17.4844L11.0539 17.2523C10.9579 17.2223 10.8789 17.3319 10.9377 17.4134L11.3918 18.0442L10.9419 18.678C10.8837 18.7601 10.9634 18.8691 11.0592 18.8384L11.7996 18.6014L12.2634 19.2252C12.3234 19.3059 12.4517 19.2637 12.4521 19.1632L12.4556 18.3859L13.1921 18.1375C13.2875 18.1052 13.287 17.9703 13.1914 17.9387L12.4533 17.6953L12.4447 16.918C12.4434 16.8176 12.3149 16.7763 12.2554 16.8575Z"
                  fill="#FFE15A"
                />
                <path
                  d="M15.0544 15.1022L14.2816 15.1861L13.9519 14.4822C13.9092 14.3911 13.7752 14.4067 13.7546 14.5052L13.5956 15.266L12.8243 15.362C12.7244 15.3744 12.6979 15.5068 12.7852 15.5568L13.4597 15.943L13.3126 16.7063C13.2936 16.8051 13.4113 16.8713 13.4859 16.8037L14.0616 16.2815L14.742 16.6572C14.8301 16.7059 14.9295 16.6143 14.8882 16.5225L14.5695 15.8135L15.1372 15.2825C15.2108 15.2141 15.1545 15.0914 15.0544 15.1022Z"
                  fill="#FFE15A"
                />
              </svg>
              <div>
                <h3 className="text-sm">China - Mandarin</h3>
                <span className="input-titel text-spiroDiscoBall">
                  Renminbi
                </span>
              </div>
            </div>
            <div>
              <input
                type="radio"
                name="select"
                id="selact9"
                className="w-6 h-6 rounded-full bg-brightGray appearance-none hidden"
                onChange={() => {
                  onSelectLanguage("ch");
                }}
                checked={selectLanguage === "ch"}
              />
              <div className="w-6 h-6 flex items-center justify-center rounded-full bg-brightGray overflow-hidden check-language">
                <i className="icon-right text-[10px] scale-0 origin-center duration-300 transition-all"></i>
              </div>
            </div>
          </label>
          <label
            htmlFor="selact10"
            className="bg-white rounded-md py-3 px-5 flex items-center justify-between cursor-pointer"
          >
            <div className="flex items-start space-x-4">
              <svg
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.670315 32.2736H38.3297C38.7029 32.2736 39 31.9689 39 31.6033V7.3959C39 7.02266 38.6953 6.72559 38.3297 6.72559H0.677933C0.304691 6.72559 0.00762177 7.03027 0.00762177 7.3959V31.6033C3.8147e-06 31.9766 0.304691 32.2736 0.670315 32.2736Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M0.670311 32.2736H38.3297C38.7029 32.2736 39 31.9689 39 31.6033V28.2441H0V31.6033C0 31.9766 0.304688 32.2736 0.670311 32.2736Z"
                  fill="#FF4B55"
                />
                <path
                  d="M38.3297 6.72559H0.677929C0.304688 6.72559 0.00761795 7.03027 0.00761795 7.3959V10.7551H39V7.3959C39 7.02266 38.6953 6.72559 38.3297 6.72559Z"
                  fill="#FF4B55"
                />
                <path d="M0 14.793H39V24.2078H0V14.793Z" fill="#41479B" />
              </svg>
              <div>
                <h3 className="text-sm">Thailand - Thai</h3>
                <span className="input-titel text-spiroDiscoBall">
                  Thai Baht
                </span>
              </div>
            </div>
            <div>
              <input
                type="radio"
                name="select"
                id="selact10"
                className="w-6 h-6 rounded-full bg-brightGray appearance-none hidden"
                onChange={() => {
                  onSelectLanguage("th");
                }}
                checked={selectLanguage === "th"}
              />
              <div className="w-6 h-6 flex items-center justify-center rounded-full bg-brightGray overflow-hidden check-language">
                <i className="icon-right text-[10px] scale-0 origin-center duration-300 transition-all"></i>
              </div>
            </div>
          </label>
          {/*<label
                        htmlFor="selact11"
                        className="bg-white rounded-md py-3 px-5 flex items-center justify-between cursor-pointer"
                    >
                        <div className="flex items-start space-x-4">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                xmlnsSvgjs="http://svgjs.com/svgjs"
                                width="39"
                                height="39"
                                x="0"
                                y="0"
                                viewBox="0 0 512 512"
                                //  style={{enableBackground : "new 0 0 512 512"}}
                                style={{ enableBackground: "new 0 0 512 512" }}
                                xmlSpace="preserve"
                                className=""
                            >
                                <g>
                                    <g
                                        xmlns="http://www.w3.org/2000/svg"
                                        id="_x30_7_x2C__Spain_x2C__country_x2C__national_x2C__flag_x2C__world_flag"
                                    >
                                        <g id="XMLID_684_">
                                            <path
                                                id="XMLID_623_"
                                                d="m16 196v-120h480v120l-240 10z"
                                                fill="#d6002a"
                                                data-original="#d6002a"
                                                className=""
                                            ></path>
                                            <path
                                                id="XMLID_622_"
                                                d="m16 436v-120l240-10 240 10v120z"
                                                fill="#d6002a"
                                                data-original="#d6002a"
                                                className=""
                                            ></path>
                                            <path
                                                id="XMLID_621_"
                                                d="m16 196h480v120h-480z"
                                                fill="#fddb47"
                                                data-original="#fddb47"
                                                className=""
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <div>
                                <h3 className="text-sm">Spain - Spanish</h3>
                                <span className="input-titel text-spiroDiscoBall">Euro</span>
                            </div>
                        </div>
                        <div>
                            <input
                                type="radio"
                                name="select"
                                id="selact11"
                                className="w-6 h-6 rounded-full bg-brightGray appearance-none hidden"
                            />
                            <div className="w-6 h-6 flex items-center justify-center rounded-full bg-brightGray overflow-hidden check-language">
                                <i className="icon-right text-[10px] scale-0 origin-center duration-300 transition-all"></i>
                            </div>
                        </div>
                    </label>
                    <label
                        htmlFor="selact12"
                        className="bg-white rounded-md py-3 px-5 flex items-center justify-between cursor-pointer"
                    >
                        <div className="flex items-start space-x-4">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                xmlnsSvgjs="http://svgjs.com/svgjs"
                                width="39"
                                height="39"
                                x="0"
                                y="0"
                                viewBox="0 0 512 512"
                                style={{ enableBackground: "new 0 0 512 512" }}
                                xmlSpace="preserve"
                                className=""
                            >
                                <g>
                                    <path
                                        xmlns="http://www.w3.org/2000/svg"
                                        d="m480 96h-448v320h448z"
                                        fill="#e92b2b"
                                        data-original="#e92b2b"
                                    ></path>
                                    <path
                                        xmlns="http://www.w3.org/2000/svg"
                                        d="m32 416h160v-320h-160z"
                                        fill="#32ab45"
                                        data-original="#32ab45"
                                    ></path>
                                    <g
                                        xmlns="http://www.w3.org/2000/svg"
                                        clipRule="evenodd"
                                        fill="#ffe70e"
                                        fillRule="evenodd"
                                    >
                                        <path
                                            d="m192 208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm-64 48c0-35.346 28.654-64 64-64s64 28.654 64 64-28.654 64-64 64-64-28.654-64-64z"
                                            fill="#ffe70e"
                                            data-original="#ffe70e"
                                        ></path>
                                        <path
                                            d="m226.344 301.657-80-80 11.313-11.313 80 80z"
                                            fill="#ffe70e"
                                            data-original="#ffe70e"
                                        ></path>
                                        <path
                                            d="m184 312.567v-113.137h16v113.137z"
                                            fill="#ffe70e"
                                            data-original="#ffe70e"
                                        ></path>
                                        <path
                                            d="m135.988 248.57h113.137v16h-113.137z"
                                            fill="#ffe70e"
                                            data-original="#ffe70e"
                                        ></path>
                                        <path
                                            d="m157.656 301.657 80-80-11.313-11.313-80 80z"
                                            fill="#ffe70e"
                                            data-original="#ffe70e"
                                        ></path>
                                    </g>
                                    <path
                                        xmlns="http://www.w3.org/2000/svg"
                                        d="m216 224h-48c-4.418 0-8 3.582-8 8v32c0 17.673 14.327 32 32 32s32-14.327 32-32v-32c0-4.418-3.582-8-8-8z"
                                        fill="#e92b2b"
                                        data-original="#e92b2b"
                                    ></path>
                                    <path
                                        xmlns="http://www.w3.org/2000/svg"
                                        d="m208 240h-32v24c0 8.837 7.163 16 16 16s16-7.163 16-16z"
                                        fill="#fbfbfb"
                                        data-original="#fbfbfb"
                                    ></path>
                                </g>
                            </svg>
                            <div>
                                <h3 className="text-sm">Protugal - Protuguese</h3>
                                <span className="input-titel text-spiroDiscoBall">Euro</span>
                            </div>
                        </div>
                        <div>
                            <input
                                type="radio"
                                name="select"
                                id="selact12"
                                className="w-6 h-6 rounded-full bg-brightGray appearance-none hidden"
                            />
                            <div className="w-6 h-6 flex items-center justify-center rounded-full bg-brightGray overflow-hidden check-language">
                                <i className="icon-right text-[10px] scale-0 origin-center duration-300 transition-all"></i>
                            </div>
                        </div>
                    </label>
                    <label
                        htmlFor="selact13"
                        className="bg-white rounded-md py-3 px-5 flex items-center justify-between cursor-pointer"
                    >
                        <div className="flex items-start space-x-4">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                xmlnsSvgjs="http://svgjs.com/svgjs"
                                width="39"
                                height="39"
                                x="0"
                                y="0"
                                viewBox="0 0 512.001 512.001"
                                style={{ enableBackground: "new 0 0 512 512" }}
                                xmlSpace="preserve"
                                className=""
                            >
                                <g>
                                    <path
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{}}
                                        d="M512,200.093H0V97.104c0-4.875,3.953-8.828,8.828-8.828h494.345c4.875,0,8.828,3.953,8.828,8.828  L512,200.093L512,200.093z"
                                        fill="#f5f5f5"
                                        data-original="#f5f5f5"
                                    ></path>
                                    <path
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{}}
                                        d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V311.909h512v102.988  C512,419.773,508.047,423.725,503.172,423.725z"
                                        fill="#ff4b55"
                                        data-original="#ff4b55"
                                        className=""
                                    ></path>
                                    <rect
                                        xmlns="http://www.w3.org/2000/svg"
                                        y="200.091"
                                        style={{}}
                                        width="512"
                                        height="111.81"
                                        fill="#41479b"
                                        data-original="#41479b"
                                    ></rect>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                </g>
                            </svg>
                            <div>
                                <h3 className="text-sm">Russia - Russian</h3>
                                <span className="input-titel text-spiroDiscoBall">
                                    Russian Ruble
                                </span>
                            </div>
                        </div>
                        <div>
                            <input
                                type="radio"
                                name="select"
                                id="selact13"
                                className="w-6 h-6 rounded-full bg-brightGray appearance-none hidden"
                            />
                            <div className="w-6 h-6 flex items-center justify-center rounded-full bg-brightGray overflow-hidden check-language">
                                <i className="icon-right text-[10px] scale-0 origin-center duration-300 transition-all"></i>
                            </div>
                        </div>
                    </label>
                    <label
                        htmlFor="selact14"
                        className="bg-white rounded-md py-3 px-5 flex items-center justify-between cursor-pointer"
                    >
                        <div className="flex items-start space-x-4">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                xmlnsSvgjs="http://svgjs.com/svgjs"
                                width="39"
                                height="39"
                                x="0"
                                y="0"
                                viewBox="0 0 64 64"
                                style={{ enableBackground: "new 0 0 512 512" }}
                                xmlSpace="preserve"
                                className=""
                            >
                                <g>
                                    <g xmlns="http://www.w3.org/2000/svg" id="PAKISTAN">
                                        <g>
                                            <path
                                                d="m2.801 14.962h14.594v34.075h-14.594z"
                                                fill="#e1e1e1"
                                                data-original="#e1e1e1"
                                            ></path>
                                            <path
                                                d="m17.395 49.038h43.805v-34.076h-43.805zm24.703-24.688 1.641 1.852 2.26-.988-1.247 2.133 1.644 1.85-2.414-.533-1.246 2.137-.247-2.463-2.407-.527 2.264-.995zm-6.813-2.088c-.172.133-.345.263-.51.41-3.861 3.44-4.209 9.367-.783 13.227 3.434 3.868 9.344 4.222 13.205.783.16-.143.306-.296.453-.447-1.605 3.53-5.146 5.987-9.273 5.987-5.635 0-10.201-4.574-10.201-10.223 0-4.564 2.987-8.426 7.109-9.737z"
                                                fill="#2e6934"
                                                data-original="#2e6934"
                                            ></path>
                                            <g fill="#e1e1e1">
                                                <path
                                                    d="m38.377 42.22c4.127 0 7.668-2.457 9.273-5.987-.147.151-.293.304-.453.447-3.861 3.439-9.771 3.085-13.205-.783-3.426-3.86-3.078-9.787.783-13.227.165-.146.338-.277.51-.41-4.122 1.312-7.109 5.173-7.109 9.735 0 5.651 4.566 10.225 10.201 10.225z"
                                                    fill="#e1e1e1"
                                                    data-original="#e1e1e1"
                                                ></path>
                                                <path
                                                    d="m42.487 28.337.247 2.464 1.246-2.137 2.415.533-1.644-1.85 1.247-2.134-2.26.989-1.64-1.852.246 2.466-2.264.995z"
                                                    fill="#e1e1e1"
                                                    data-original="#e1e1e1"
                                                ></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <div>
                                <h3 className="text-sm">pakistan - Urdu</h3>
                                <span className="input-titel text-spiroDiscoBall">
                                    Pakistani Rupee
                                </span>
                            </div>
                        </div>
                        <div>
                            <input
                                type="radio"
                                name="select"
                                id="selact14"
                                className="w-6 h-6 rounded-full bg-brightGray appearance-none hidden"
                            />
                            <div className="w-6 h-6 flex items-center justify-center rounded-full bg-brightGray overflow-hidden check-language">
                                <i className="icon-right text-[10px] scale-0 origin-center duration-300 transition-all"></i>
                            </div>
                        </div>
                    </label>
                    <label
                        htmlFor="selact15"
                        className="bg-white rounded-md py-3 px-5 flex items-center justify-between cursor-pointer"
                    >
                        <div className="flex items-start space-x-4">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                xmlnsSvgjs="http://svgjs.com/svgjs"
                                width="39"
                                height="39"
                                x="0"
                                y="0"
                                viewBox="0 0 32 32"
                                style={{ enableBackground: "new 0 0 512 512" }}
                                xmlSpace="preserve"
                                className=""
                            >
                                <g>
                                    <path
                                        xmlns="http://www.w3.org/2000/svg"
                                        d="m0 5.5h32v21h-32z"
                                        fill="#496e2d"
                                        data-original="#496e2d"
                                        className=""
                                    ></path>
                                    <path
                                        xmlns="http://www.w3.org/2000/svg"
                                        d="m16 5.5h16v21h-16z"
                                        fill="#f0f0f0"
                                        data-original="#f0f0f0"
                                    ></path>
                                    <g xmlns="http://www.w3.org/2000/svg" fill="#d80027">
                                        <path
                                            d="m19.644 12.941-1.313 1.803-2.126-.687 1.315 1.802-1.313 1.803 2.125-.69 1.315 1.801-.001-2.227 2.125-.69-2.126-.687z"
                                            fill="#d80027"
                                            data-original="#d80027"
                                        ></path>
                                        <path
                                            d="m17.533 20.507a4.507 4.507 0 1 1 2.151-8.472 5.547 5.547 0 1 0 0 7.93 4.5 4.5 0 0 1 -2.151.542z"
                                            fill="#d80027"
                                            data-original="#d80027"
                                        ></path>
                                    </g>
                                </g>
                            </svg>
                            <div>
                                <h3 className="text-sm">Algeria - Standard Arabic</h3>
                                <span className="input-titel text-spiroDiscoBall">
                                    Algerian Dinar
                                </span>
                            </div>
                        </div>
                        <div>
                            <input
                                type="radio"
                                name="select"
                                id="selact15"
                                className="w-6 h-6 rounded-full bg-brightGray appearance-none hidden"
                            />
                            <div className="w-6 h-6 flex items-center justify-center rounded-full bg-brightGray overflow-hidden check-language">
                                <i className="icon-right text-[10px] scale-0 origin-center duration-300 transition-all"></i>
                            </div>
                        </div>
                    </label>
                    <label
                        htmlFor="selact16"
                        className="bg-white rounded-md py-3 px-5 flex items-center justify-between cursor-pointer"
                    >
                        <div className="flex items-start space-x-4">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                xmlnsSvgjs="http://svgjs.com/svgjs"
                                width="39"
                                height="39"
                                x="0"
                                y="0"
                                viewBox="0 0 512 512"
                                style={{ enableBackground: "new 0 0 512 512" }}
                                xmlSpace="preserve"
                                className=""
                            >
                                <g>
                                    <path
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{}}
                                        d="M473.655,88.276H38.345C17.167,88.276,0,105.443,0,126.621V256h512V126.621  C512,105.443,494.833,88.276,473.655,88.276z"
                                        fill="#c8414b"
                                        data-original="#c8414b"
                                    ></path>
                                    <path
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{}}
                                        d="M0,385.379c0,21.177,17.167,38.345,38.345,38.345h435.31c21.177,0,38.345-17.167,38.345-38.345V256H0  V385.379z"
                                        fill="#f5f5f5"
                                        data-original="#f5f5f5"
                                        className=""
                                    ></path>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                </g>
                            </svg>
                            <div>
                                <h3 className="text-sm">Indonesia - Indonesian</h3>
                                <span className="input-titel text-spiroDiscoBall">
                                    Indonesian Rupiah
                                </span>
                            </div>
                        </div>
                        <div>
                            <input
                                type="radio"
                                name="select"
                                id="selact16"
                                className="w-6 h-6 rounded-full bg-brightGray appearance-none hidden"
                            />
                            <div className="w-6 h-6 flex items-center justify-center rounded-full bg-brightGray overflow-hidden check-language">
                                <i className="icon-right text-[10px] scale-0 origin-center duration-300 transition-all"></i>
                            </div>
                        </div>
                    </label>
                    <label
                        htmlFor="selact17"
                        className="bg-white rounded-md py-3 px-5 flex items-center justify-between cursor-pointer"
                    >
                        <div className="flex items-start space-x-4">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                xmlnsSvgjs="http://svgjs.com/svgjs"
                                width="39"
                                height="39"
                                x="0"
                                y="0"
                                viewBox="0 0 512 512"
                                style={{ enableBackground: "new 0 0 512 512" }}
                                xmlSpace="preserve"
                                className=""
                            >
                                <g>
                                    <rect
                                        xmlns="http://www.w3.org/2000/svg"
                                        y="85.331"
                                        style={{}}
                                        width="512"
                                        height="341.337"
                                        fill="#f0f0f0"
                                        data-original="#f0f0f0"
                                        className=""
                                    ></rect>
                                    <circle
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{}}
                                        cx="256"
                                        cy="255.994"
                                        r="96"
                                        fill="#d80027"
                                        data-original="#d80027"
                                    ></circle>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                </g>
                            </svg>
                            <div>
                                <h3 className="text-sm">Japan - Japanese</h3>
                                <span className="input-titel text-spiroDiscoBall">
                                    Japanese Yen
                                </span>
                            </div>
                        </div>
                        <div>
                            <input
                                type="radio"
                                name="select"
                                id="selact17"
                                className="w-6 h-6 rounded-full bg-brightGray appearance-none hidden"
                            />
                            <div className="w-6 h-6 flex items-center justify-center rounded-full bg-brightGray overflow-hidden check-language">
                                <i className="icon-right text-[10px] scale-0 origin-center duration-300 transition-all"></i>
                            </div>
                        </div>
                    </label>
                    <label
                        htmlFor="selact18"
                        className="bg-white rounded-md py-3 px-5 flex items-center justify-between cursor-pointer"
                    >
                        <div className="flex items-start space-x-4">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                xmlnsSvgjs="http://svgjs.com/svgjs"
                                width="39"
                                height="39"
                                x="0"
                                y="0"
                                viewBox="0 0 512.001 512.001"
                                style={{ enableBackground: "new 0 0 512 512" }}
                                xmlSpace="preserve"
                                className=""
                            >
                                <g>
                                    <path
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{}}
                                        d="M0,311.908h512v102.988c0,4.875-3.953,8.828-8.828,8.828H8.828c-4.875,0-8.828-3.953-8.828-8.828  V311.908z"
                                        fill="#73af00"
                                        data-original="#73af00"
                                    ></path>
                                    <path
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{}}
                                        d="M8.828,88.276h494.345c4.875,0,8.828,3.953,8.828,8.828v102.988H0V97.103  C0,92.228,3.953,88.276,8.828,88.276z"
                                        fill="#464655"
                                        data-original="#464655"
                                        className=""
                                    ></path>
                                    <rect
                                        xmlns="http://www.w3.org/2000/svg"
                                        y="200.09"
                                        style={{}}
                                        width="512"
                                        height="111.81"
                                        fill="#c8414b"
                                        data-original="#c8414b"
                                    ></rect>
                                    <g xmlns="http://www.w3.org/2000/svg">
                                        <rect
                                            y="194.21"
                                            style={{}}
                                            width="512"
                                            height="17.655"
                                            fill="#f5f5f5"
                                            data-original="#f5f5f5"
                                        ></rect>
                                        <rect
                                            y="300.141"
                                            style={{}}
                                            width="512"
                                            height="17.655"
                                            fill="#f5f5f5"
                                            data-original="#f5f5f5"
                                        ></rect>
                                        <path
                                            style={{}}
                                            d="M303.268,181.618c7.108-14.657,14.525-48.61,14.525-48.61s-22.072,26.845-29.182,41.501   c-1.49,3.072-0.858,6.605,1.294,8.995l-80.967,166.934l5.496,2.666l80.967-166.934C298.613,186.381,301.779,184.69,303.268,181.618   z"
                                            fill="#f5f5f5"
                                            data-original="#f5f5f5"
                                        ></path>
                                        <path
                                            style={{}}
                                            d="M208.732,181.618c-7.108-14.657-14.525-48.61-14.525-48.61s22.072,26.845,29.182,41.501   c1.49,3.072,0.858,6.605-1.294,8.995l80.967,166.934l-5.496,2.666L216.599,186.17C213.387,186.381,210.221,184.69,208.732,181.618z   "
                                            fill="#f5f5f5"
                                            data-original="#f5f5f5"
                                        ></path>
                                    </g>
                                    <path
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{}}
                                        d="M300.138,256c0,48.754-36.012,88.276-44.138,88.276S211.862,304.754,211.862,256  s36.012-88.276,44.138-88.276S300.138,207.246,300.138,256z"
                                        fill="#c8414b"
                                        data-original="#c8414b"
                                    ></path>
                                    <g xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            style={{}}
                                            d="M291.31,298.591c5.199-12.631,8.828-27.14,8.828-42.591s-3.628-29.959-8.828-42.591   c-5.199,12.631-8.828,27.14-8.828,42.591S286.111,285.96,291.31,298.591z"
                                            fill="#464655"
                                            data-original="#464655"
                                            className=""
                                        ></path>
                                        <path
                                            style={{}}
                                            d="M220.69,213.409c-5.199,12.631-8.828,27.14-8.828,42.591s3.628,29.959,8.828,42.591   c5.199-12.631,8.828-27.14,8.828-42.591S225.889,226.041,220.69,213.409z"
                                            fill="#464655"
                                            data-original="#464655"
                                            className=""
                                        ></path>
                                    </g>
                                    <g xmlns="http://www.w3.org/2000/svg">
                                        <ellipse
                                            style={{}}
                                            cx="256"
                                            cy="256"
                                            rx="8.828"
                                            ry="13.241"
                                            fill="#f5f5f5"
                                            data-original="#f5f5f5"
                                        ></ellipse>
                                        <path
                                            style={{}}
                                            d="M257.324,167.724v75.034c2.407-5.01,6.676-19.815,6.676-37.517   C264,187.538,259.731,172.734,257.324,167.724z"
                                            fill="#f5f5f5"
                                            data-original="#f5f5f5"
                                        ></path>
                                        <path
                                            style={{}}
                                            d="M254.676,167.724c-2.407,5.01-6.676,19.814-6.676,37.517s4.269,32.508,6.676,37.517V167.724z"
                                            fill="#f5f5f5"
                                            data-original="#f5f5f5"
                                        ></path>
                                        <path
                                            style={{}}
                                            d="M257.324,269.241v75.034c2.407-5.01,6.676-19.815,6.676-37.517   C264,289.055,259.731,274.251,257.324,269.241z"
                                            fill="#f5f5f5"
                                            data-original="#f5f5f5"
                                        ></path>
                                        <path
                                            style={{}}
                                            d="M254.676,269.241c-2.407,5.01-6.676,19.814-6.676,37.517s4.269,32.508,6.676,37.517V269.241z"
                                            fill="#f5f5f5"
                                            data-original="#f5f5f5"
                                        ></path>
                                    </g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                </g>
                            </svg>
                            <div>
                                <h3 className="text-sm">kenya - Swahili</h3>
                                <span className="input-titel text-spiroDiscoBall">
                                    Kenyan Shilling
                                </span>
                            </div>
                        </div>
                        <div>
                            <input
                                type="radio"
                                name="select"
                                id="selact18"
                                className="w-6 h-6 rounded-full bg-brightGray appearance-none hidden"
                            />
                            <div className="w-6 h-6 flex items-center justify-center rounded-full bg-brightGray overflow-hidden check-language">
                                <i className="icon-right text-[10px] scale-0 origin-center duration-300 transition-all"></i>
                            </div>
                        </div>
                    </label> */}
          <label
            htmlFor="selact19"
            className="bg-white rounded-md py-3 px-5 flex items-center justify-between cursor-pointer"
          >
            <div className="flex items-start space-x-4">
              <svg
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M38.3275 32.2754H0.672444C0.301107 32.2754 0 31.9743 0 31.6029V7.39608C0 7.02474 0.301107 6.72363 0.672444 6.72363H38.3276C38.6989 6.72363 39 7.02474 39 7.39608V31.6029C38.9999 31.9743 38.6988 32.2754 38.3275 32.2754Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M38.9999 8.68924H0V7.39608C0 7.02474 0.301107 6.72363 0.672444 6.72363H38.3276C38.6989 6.72363 39 7.02474 39 7.39608L38.9999 8.68924Z"
                  fill="#FF4B55"
                />
                <path
                  d="M38.9999 14.5859H0V16.5515H38.9999V14.5859Z"
                  fill="#FF4B55"
                />
                <path
                  d="M38.9999 10.6543H0V12.6198H38.9999V10.6543Z"
                  fill="#FF4B55"
                />
                <path
                  d="M38.9999 20.4822H0.672444C0.301107 20.4822 0 20.1811 0 19.8098V18.5166H38.9999V20.4822Z"
                  fill="#FF4B55"
                />
                <path
                  d="M38.9999 26.3789H0V28.3444H38.9999V26.3789Z"
                  fill="#FF4B55"
                />
                <path
                  d="M38.3275 32.2751H0.672444C0.301107 32.2751 0 31.974 0 31.6027V30.3096H38.9999V31.6027C38.9999 31.9741 38.6988 32.2751 38.3275 32.2751Z"
                  fill="#FF4B55"
                />
                <path
                  d="M38.9999 22.4482H0V24.4138H38.9999V22.4482Z"
                  fill="#FF4B55"
                />
                <path
                  d="M17.4827 6.72461H0.672444C0.301107 6.72461 0 7.02564 0 7.39698V19.8107C0 20.182 0.301107 20.4831 0.672444 20.4831H17.4828C17.8541 20.4831 18.1552 20.182 18.1552 19.8107V7.39698C18.1552 7.02564 17.854 6.72461 17.4827 6.72461Z"
                  fill="#41479B"
                />
                <path
                  d="M1.88802 8.26796L2.03686 8.71433L2.50737 8.71791C2.56831 8.71844 2.59352 8.79613 2.54455 8.83232L2.16605 9.11187L2.30803 9.56044C2.32647 9.61848 2.26027 9.66662 2.21068 9.63113L1.82792 9.3576L1.44516 9.6312C1.39557 9.66662 1.32953 9.61856 1.34781 9.56052L1.4898 9.11194L1.1113 8.83239C1.06232 8.79613 1.08753 8.71852 1.14847 8.71798L1.61898 8.7144L1.76782 8.26804C1.78702 8.21015 1.86867 8.21015 1.88802 8.26796Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M1.88802 10.6029L2.03686 11.0492L2.50737 11.0528C2.56831 11.0533 2.59352 11.131 2.54455 11.1672L2.16605 11.4468L2.30803 11.8953C2.32647 11.9534 2.26027 12.0015 2.21068 11.966L1.82792 11.6925L1.44516 11.9661C1.39557 12.0015 1.32953 11.9535 1.34781 11.8954L1.4898 11.4468L1.1113 11.1673C1.06232 11.131 1.08753 11.0534 1.14847 11.0529L1.61898 11.0493L1.76782 10.6029C1.78702 10.5451 1.86867 10.5451 1.88802 10.6029Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M1.88802 12.9378L2.03686 13.3842L2.50737 13.3878C2.56831 13.3883 2.59352 13.466 2.54455 13.5022L2.16605 13.7817L2.30803 14.2303C2.32647 14.2884 2.26027 14.3365 2.21068 14.301L1.82792 14.0275L1.44516 14.3011C1.39557 14.3365 1.32953 14.2884 1.34781 14.2304L1.4898 13.7818L1.1113 13.5023C1.06232 13.466 1.08753 13.3884 1.14847 13.3878L1.61898 13.3843L1.76782 12.9379C1.78702 12.8801 1.86867 12.8801 1.88802 12.9378Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M1.88802 15.2728L2.03686 15.7192L2.50737 15.7228C2.56831 15.7233 2.59352 15.801 2.54455 15.8372L2.16605 16.1167L2.30803 16.5653C2.32647 16.6234 2.26027 16.6715 2.21068 16.636L1.82792 16.3625L1.44516 16.6361C1.39557 16.6715 1.32953 16.6234 1.34781 16.5654L1.4898 16.1168L1.1113 15.8373C1.06232 15.801 1.08753 15.7234 1.14847 15.7229L1.61898 15.7193L1.76782 15.2729C1.78702 15.215 1.86867 15.215 1.88802 15.2728Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M1.88802 17.6079L2.03686 18.0542L2.50737 18.0578C2.56831 18.0583 2.59352 18.136 2.54455 18.1722L2.16605 18.4518L2.30803 18.9003C2.32647 18.9584 2.26027 19.0065 2.21068 18.971L1.82792 18.6975L1.44516 18.9711C1.39557 19.0065 1.32953 18.9585 1.34781 18.9004L1.4898 18.4518L1.1113 18.1723C1.06232 18.136 1.08753 18.0584 1.14847 18.0579L1.61898 18.0543L1.76782 17.6079C1.78702 17.55 1.86867 17.55 1.88802 17.6079Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M3.70052 9.41244L3.84936 9.85881L4.31987 9.86239C4.38081 9.86292 4.40602 9.94062 4.35704 9.9768L3.97855 10.2563L4.12053 10.7049C4.13897 10.763 4.07277 10.8111 4.02318 10.7756L3.64042 10.502L3.25766 10.7756C3.20807 10.811 3.14203 10.763 3.16031 10.7049L3.3023 10.2563L2.9238 9.9768C2.87482 9.94054 2.90003 9.86292 2.96097 9.86239L3.43148 9.85881L3.58032 9.41244C3.59944 9.35471 3.68125 9.35471 3.70052 9.41244Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M3.70052 11.7475L3.84936 12.1938L4.31987 12.1974C4.38081 12.1979 4.40602 12.2756 4.35704 12.3118L3.97855 12.5914L4.12053 13.0399C4.13897 13.098 4.07277 13.1461 4.02318 13.1106L3.64042 12.837L3.25766 13.1106C3.20807 13.146 3.14203 13.0981 3.16031 13.0399L3.3023 12.5914L2.9238 12.3118C2.87482 12.2756 2.90003 12.1979 2.96097 12.1974L3.43148 12.1938L3.58032 11.7475C3.59944 11.6896 3.68125 11.6896 3.70052 11.7475Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M3.70052 14.0824L3.84936 14.5288L4.31987 14.5324C4.38081 14.5329 4.40602 14.6106 4.35704 14.6468L3.97855 14.9263L4.12053 15.3749C4.13897 15.4329 4.07277 15.4811 4.02318 15.4456L3.64042 15.172L3.25766 15.4456C3.20807 15.481 3.14203 15.4329 3.16031 15.3749L3.3023 14.9263L2.9238 14.6468C2.87482 14.6105 2.90003 14.5329 2.96097 14.5324L3.43148 14.5288L3.58032 14.0824C3.59944 14.0246 3.68125 14.0246 3.70052 14.0824Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M3.70052 16.4173L3.84936 16.8637L4.31987 16.8673C4.38081 16.8678 4.40602 16.9455 4.35704 16.9817L3.97855 17.2612L4.12053 17.7098C4.13897 17.7679 4.07277 17.816 4.02318 17.7805L3.64042 17.5069L3.25766 17.7805C3.20807 17.8159 3.14203 17.7679 3.16031 17.7098L3.3023 17.2612L2.9238 16.9817C2.87482 16.9454 2.90003 16.8678 2.96097 16.8673L3.43148 16.8637L3.58032 16.4173C3.59944 16.3596 3.68125 16.3596 3.70052 16.4173Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M5.51253 8.26797L5.66137 8.71434L6.13189 8.71792C6.19283 8.71845 6.21804 8.79614 6.16906 8.83232L5.79056 9.11187L5.93255 9.56045C5.95098 9.61849 5.88479 9.66663 5.8352 9.63114L5.45244 9.35753L5.06967 9.63114C5.02008 9.66656 4.95404 9.61849 4.97232 9.56045L5.11431 9.11187L4.73581 8.83232C4.68683 8.79607 4.71204 8.71845 4.77298 8.71792L5.2435 8.71434L5.39234 8.26797C5.41153 8.21016 5.49326 8.21016 5.51253 8.26797Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M5.51253 10.6029L5.66137 11.0492L6.13189 11.0528C6.19283 11.0534 6.21804 11.131 6.16906 11.1672L5.79056 11.4468L5.93255 11.8954C5.95098 11.9534 5.88479 12.0015 5.8352 11.966L5.45244 11.6924L5.06967 11.966C5.02008 12.0015 4.95404 11.9534 4.97232 11.8954L5.11431 11.4468L4.73581 11.1672C4.68683 11.131 4.71204 11.0534 4.77298 11.0528L5.2435 11.0492L5.39234 10.6029C5.41153 10.5451 5.49326 10.5451 5.51253 10.6029Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M5.51253 12.9378L5.66137 13.3842L6.13189 13.3878C6.19283 13.3883 6.21804 13.466 6.16906 13.5022L5.79056 13.7817L5.93255 14.2303C5.95098 14.2884 5.88479 14.3365 5.8352 14.301L5.45244 14.0274L5.06967 14.301C5.02008 14.3364 4.95404 14.2884 4.97232 14.2303L5.11431 13.7817L4.73581 13.5022C4.68683 13.4659 4.71204 13.3883 4.77298 13.3878L5.2435 13.3842L5.39234 12.9378C5.41153 12.8801 5.49326 12.8801 5.51253 12.9378Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M5.51253 15.2729L5.66137 15.7192L6.13189 15.7228C6.19283 15.7233 6.21804 15.801 6.16906 15.8372L5.79056 16.1168L5.93255 16.5653C5.95098 16.6234 5.88479 16.6715 5.8352 16.636L5.45244 16.3624L5.06967 16.636C5.02008 16.6714 4.95404 16.6234 4.97232 16.5653L5.11431 16.1168L4.73581 15.8372C4.68683 15.801 4.71204 15.7233 4.77298 15.7228L5.2435 15.7192L5.39234 15.2729C5.41153 15.215 5.49326 15.215 5.51253 15.2729Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M5.51253 17.6079L5.66137 18.0542L6.13189 18.0578C6.19283 18.0584 6.21804 18.136 6.16906 18.1722L5.79056 18.4518L5.93255 18.9004C5.95098 18.9584 5.88479 19.0065 5.8352 18.971L5.45244 18.6974L5.06967 18.971C5.02008 19.0065 4.95404 18.9584 4.97232 18.9004L5.11431 18.4518L4.73581 18.1722C4.68683 18.136 4.71204 18.0584 4.77298 18.0578L5.2435 18.0542L5.39234 17.6079C5.41153 17.55 5.49326 17.55 5.51253 17.6079Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M7.32503 9.41244L7.47387 9.85881L7.94439 9.86239C8.00532 9.86292 8.03054 9.94062 7.98156 9.9768L7.60306 10.2563L7.74504 10.7049C7.76348 10.763 7.69728 10.8111 7.6477 10.7756L7.26493 10.502L6.88217 10.7756C6.83258 10.811 6.76654 10.763 6.78482 10.7049L6.92681 10.2563L6.54831 9.9768C6.49933 9.94054 6.52454 9.86292 6.58548 9.86239L7.056 9.85881L7.20483 9.41244C7.22395 9.35471 7.30576 9.35471 7.32503 9.41244Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M7.32503 11.7475L7.47387 12.1938L7.94439 12.1974C8.00532 12.1979 8.03054 12.2756 7.98156 12.3118L7.60306 12.5914L7.74504 13.0399C7.76348 13.098 7.69728 13.1461 7.6477 13.1106L7.26493 12.837L6.88217 13.1106C6.83258 13.146 6.76654 13.0981 6.78482 13.0399L6.92681 12.5914L6.54831 12.3118C6.49933 12.2756 6.52454 12.1979 6.58548 12.1974L7.056 12.1938L7.20483 11.7475C7.22395 11.6896 7.30576 11.6896 7.32503 11.7475Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M7.32503 14.0824L7.47387 14.5288L7.94439 14.5324C8.00532 14.5329 8.03054 14.6106 7.98156 14.6468L7.60306 14.9263L7.74504 15.3749C7.76348 15.4329 7.69728 15.4811 7.6477 15.4456L7.26493 15.172L6.88217 15.4456C6.83258 15.481 6.76654 15.4329 6.78482 15.3749L6.92681 14.9263L6.54831 14.6468C6.49933 14.6105 6.52454 14.5329 6.58548 14.5324L7.056 14.5288L7.20483 14.0824C7.22395 14.0246 7.30576 14.0246 7.32503 14.0824Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M7.32503 16.4173L7.47387 16.8637L7.94439 16.8673C8.00532 16.8678 8.03054 16.9455 7.98156 16.9817L7.60306 17.2612L7.74504 17.7098C7.76348 17.7679 7.69728 17.816 7.6477 17.7805L7.26493 17.5069L6.88217 17.7805C6.83258 17.8159 6.76654 17.7679 6.78482 17.7098L6.92681 17.2612L6.54831 16.9817C6.49933 16.9454 6.52454 16.8678 6.58548 16.8673L7.056 16.8637L7.20483 16.4173C7.22395 16.3596 7.30576 16.3596 7.32503 16.4173Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M9.13761 8.26797L9.28645 8.71434L9.75696 8.71792C9.8179 8.71845 9.84311 8.79614 9.79413 8.83232L9.41564 9.11187L9.55762 9.56045C9.57605 9.61849 9.50986 9.66663 9.46027 9.63114L9.07743 9.35753L8.69467 9.63114C8.64508 9.66656 8.57904 9.61849 8.59732 9.56045L8.73931 9.11187L8.36081 8.83232C8.31183 8.79607 8.33704 8.71845 8.39798 8.71792L8.8685 8.71434L9.01733 8.26797C9.03661 8.21016 9.11834 8.21016 9.13761 8.26797Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M9.13761 10.6029L9.28645 11.0492L9.75696 11.0528C9.8179 11.0534 9.84311 11.131 9.79413 11.1672L9.41564 11.4468L9.55762 11.8954C9.57605 11.9534 9.50986 12.0015 9.46027 11.966L9.07743 11.6924L8.69467 11.966C8.64508 12.0015 8.57904 11.9534 8.59732 11.8954L8.73931 11.4468L8.36081 11.1672C8.31183 11.131 8.33704 11.0534 8.39798 11.0528L8.8685 11.0492L9.01733 10.6029C9.03661 10.5451 9.11834 10.5451 9.13761 10.6029Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M9.13761 12.9378L9.28645 13.3842L9.75696 13.3878C9.8179 13.3883 9.84311 13.466 9.79413 13.5022L9.41564 13.7817L9.55762 14.2303C9.57605 14.2884 9.50986 14.3365 9.46027 14.301L9.07743 14.0274L8.69467 14.301C8.64508 14.3364 8.57904 14.2884 8.59732 14.2303L8.73931 13.7817L8.36081 13.5022C8.31183 13.4659 8.33704 13.3883 8.39798 13.3878L8.8685 13.3842L9.01733 12.9378C9.03661 12.8801 9.11834 12.8801 9.13761 12.9378Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M9.13761 15.2729L9.28645 15.7192L9.75696 15.7228C9.8179 15.7233 9.84311 15.801 9.79413 15.8372L9.41564 16.1168L9.55762 16.5653C9.57605 16.6234 9.50986 16.6715 9.46027 16.636L9.07743 16.3624L8.69467 16.636C8.64508 16.6714 8.57904 16.6234 8.59732 16.5653L8.73931 16.1168L8.36081 15.8372C8.31183 15.801 8.33704 15.7233 8.39798 15.7228L8.8685 15.7192L9.01733 15.2729C9.03661 15.215 9.11834 15.215 9.13761 15.2729Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M9.13761 17.6079L9.28645 18.0542L9.75696 18.0578C9.8179 18.0584 9.84311 18.136 9.79413 18.1722L9.41564 18.4518L9.55762 18.9004C9.57605 18.9584 9.50986 19.0065 9.46027 18.971L9.07743 18.6974L8.69467 18.971C8.64508 19.0065 8.57904 18.9584 8.59732 18.9004L8.73931 18.4518L8.36081 18.1722C8.31183 18.136 8.33704 18.0584 8.39798 18.0578L8.8685 18.0542L9.01733 17.6079C9.03661 17.55 9.11834 17.55 9.13761 17.6079Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M10.95 9.41244L11.0989 9.85881L11.5694 9.86239C11.6303 9.86292 11.6555 9.94062 11.6066 9.9768L11.2281 10.2563L11.37 10.7049C11.3885 10.763 11.3223 10.8111 11.2727 10.7756L10.8899 10.502L10.5072 10.7756C10.4576 10.811 10.3915 10.763 10.4098 10.7049L10.5518 10.2563L10.1733 9.9768C10.1243 9.94054 10.1495 9.86292 10.2105 9.86239L10.681 9.85881L10.8298 9.41244C10.8491 9.35471 10.9308 9.35471 10.95 9.41244Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M10.95 11.7475L11.0989 12.1938L11.5694 12.1974C11.6303 12.1979 11.6555 12.2756 11.6066 12.3118L11.2281 12.5914L11.37 13.0399C11.3885 13.098 11.3223 13.1461 11.2727 13.1106L10.8899 12.837L10.5072 13.1106C10.4576 13.146 10.3915 13.0981 10.4098 13.0399L10.5518 12.5914L10.1733 12.3118C10.1243 12.2756 10.1495 12.1979 10.2105 12.1974L10.681 12.1938L10.8298 11.7475C10.8491 11.6896 10.9308 11.6896 10.95 11.7475Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M10.95 14.0824L11.0989 14.5288L11.5694 14.5324C11.6303 14.5329 11.6555 14.6106 11.6066 14.6468L11.2281 14.9263L11.37 15.3749C11.3885 15.4329 11.3223 15.4811 11.2727 15.4456L10.8899 15.172L10.5072 15.4456C10.4576 15.481 10.3915 15.4329 10.4098 15.3749L10.5518 14.9263L10.1733 14.6468C10.1243 14.6105 10.1495 14.5329 10.2105 14.5324L10.681 14.5288L10.8298 14.0824C10.8491 14.0246 10.9308 14.0246 10.95 14.0824Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M10.95 16.4173L11.0989 16.8637L11.5694 16.8673C11.6303 16.8678 11.6555 16.9455 11.6066 16.9817L11.2281 17.2612L11.37 17.7098C11.3885 17.7679 11.3223 17.816 11.2727 17.7805L10.8899 17.5069L10.5072 17.7805C10.4576 17.8159 10.3915 17.7679 10.4098 17.7098L10.5518 17.2612L10.1733 16.9817C10.1243 16.9454 10.1495 16.8678 10.2105 16.8673L10.681 16.8637L10.8298 16.4173C10.8491 16.3596 10.9308 16.3596 10.95 16.4173Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M12.7625 8.26797L12.9114 8.71434L13.3819 8.71792C13.4428 8.71845 13.468 8.79614 13.4191 8.83232L13.0406 9.11187L13.1825 9.56045C13.201 9.61849 13.1348 9.66663 13.0852 9.63114L12.7024 9.35753L12.3197 9.63114C12.2701 9.66656 12.204 9.61849 12.2223 9.56045L12.3643 9.11187L11.9858 8.83232C11.9368 8.79607 11.962 8.71845 12.023 8.71792L12.4935 8.71434L12.6423 8.26797C12.6615 8.21016 12.7433 8.21016 12.7625 8.26797Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M12.7625 10.6029L12.9114 11.0492L13.3819 11.0528C13.4428 11.0534 13.468 11.131 13.4191 11.1672L13.0406 11.4468L13.1825 11.8954C13.201 11.9534 13.1348 12.0015 13.0852 11.966L12.7024 11.6924L12.3197 11.966C12.2701 12.0015 12.204 11.9534 12.2223 11.8954L12.3643 11.4468L11.9858 11.1672C11.9368 11.131 11.962 11.0534 12.023 11.0528L12.4935 11.0492L12.6423 10.6029C12.6615 10.5451 12.7433 10.5451 12.7625 10.6029Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M12.7625 12.9378L12.9114 13.3842L13.3819 13.3878C13.4428 13.3883 13.468 13.466 13.4191 13.5022L13.0406 13.7817L13.1825 14.2303C13.201 14.2884 13.1348 14.3365 13.0852 14.301L12.7024 14.0274L12.3197 14.301C12.2701 14.3364 12.204 14.2884 12.2223 14.2303L12.3643 13.7817L11.9858 13.5022C11.9368 13.4659 11.962 13.3883 12.023 13.3878L12.4935 13.3842L12.6423 12.9378C12.6615 12.8801 12.7433 12.8801 12.7625 12.9378Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M12.7625 15.2729L12.9114 15.7192L13.3819 15.7228C13.4428 15.7233 13.468 15.801 13.4191 15.8372L13.0406 16.1168L13.1825 16.5653C13.201 16.6234 13.1348 16.6715 13.0852 16.636L12.7024 16.3624L12.3197 16.636C12.2701 16.6714 12.204 16.6234 12.2223 16.5653L12.3643 16.1168L11.9858 15.8372C11.9368 15.801 11.962 15.7233 12.023 15.7228L12.4935 15.7192L12.6423 15.2729C12.6615 15.215 12.7433 15.215 12.7625 15.2729Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M12.7625 17.6079L12.9114 18.0542L13.3819 18.0578C13.4428 18.0584 13.468 18.136 13.4191 18.1722L13.0406 18.4518L13.1825 18.9004C13.201 18.9584 13.1348 19.0065 13.0852 18.971L12.7024 18.6974L12.3197 18.971C12.2701 19.0065 12.204 18.9584 12.2223 18.9004L12.3643 18.4518L11.9858 18.1722C11.9368 18.136 11.962 18.0584 12.023 18.0578L12.4935 18.0542L12.6423 17.6079C12.6615 17.55 12.7433 17.55 12.7625 17.6079Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M14.5745 9.41244L14.7234 9.85881L15.1939 9.86239C15.2548 9.86292 15.28 9.94062 15.2311 9.9768L14.8526 10.2563L14.9946 10.7049C15.013 10.763 14.9468 10.8111 14.8972 10.7756L14.5144 10.502L14.1317 10.7756C14.0821 10.811 14.0161 10.763 14.0343 10.7049L14.1763 10.2563L13.7978 9.9768C13.7488 9.94054 13.7741 9.86292 13.835 9.86239L14.3055 9.85881L14.4543 9.41244C14.4735 9.35471 14.5553 9.35471 14.5745 9.41244Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M14.5745 11.7475L14.7234 12.1938L15.1939 12.1974C15.2548 12.1979 15.28 12.2756 15.2311 12.3118L14.8526 12.5914L14.9946 13.0399C15.013 13.098 14.9468 13.1461 14.8972 13.1106L14.5144 12.837L14.1317 13.1106C14.0821 13.146 14.0161 13.0981 14.0343 13.0399L14.1763 12.5914L13.7978 12.3118C13.7488 12.2756 13.7741 12.1979 13.835 12.1974L14.3055 12.1938L14.4543 11.7475C14.4735 11.6896 14.5553 11.6896 14.5745 11.7475Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M14.5745 14.0824L14.7234 14.5288L15.1939 14.5324C15.2548 14.5329 15.28 14.6106 15.2311 14.6468L14.8526 14.9263L14.9946 15.3749C15.013 15.4329 14.9468 15.4811 14.8972 15.4456L14.5144 15.172L14.1317 15.4456C14.0821 15.481 14.0161 15.4329 14.0343 15.3749L14.1763 14.9263L13.7978 14.6468C13.7488 14.6105 13.7741 14.5329 13.835 14.5324L14.3055 14.5288L14.4543 14.0824C14.4735 14.0246 14.5553 14.0246 14.5745 14.0824Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M14.5745 16.4173L14.7234 16.8637L15.1939 16.8673C15.2548 16.8678 15.28 16.9455 15.2311 16.9817L14.8526 17.2612L14.9946 17.7098C15.013 17.7679 14.9468 17.816 14.8972 17.7805L14.5144 17.5069L14.1317 17.7805C14.0821 17.8159 14.0161 17.7679 14.0343 17.7098L14.1763 17.2612L13.7978 16.9817C13.7488 16.9454 13.7741 16.8678 13.835 16.8673L14.3055 16.8637L14.4543 16.4173C14.4735 16.3596 14.5553 16.3596 14.5745 16.4173Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M16.387 8.26797L16.5359 8.71434L17.0064 8.71792C17.0673 8.71845 17.0925 8.79614 17.0436 8.83232L16.6651 9.11187L16.8071 9.56045C16.8255 9.61849 16.7593 9.66663 16.7097 9.63114L16.3269 9.35753L15.9442 9.63114C15.8946 9.66656 15.8286 9.61849 15.8468 9.56045L15.9888 9.11187L15.6103 8.83232C15.5613 8.79607 15.5866 8.71845 15.6475 8.71792L16.118 8.71434L16.2668 8.26797C16.286 8.21016 16.3677 8.21016 16.387 8.26797Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M16.387 10.6029L16.5359 11.0492L17.0064 11.0528C17.0673 11.0534 17.0925 11.131 17.0436 11.1672L16.6651 11.4468L16.8071 11.8954C16.8255 11.9534 16.7593 12.0015 16.7097 11.966L16.3269 11.6924L15.9442 11.966C15.8946 12.0015 15.8286 11.9534 15.8468 11.8954L15.9888 11.4468L15.6103 11.1672C15.5613 11.131 15.5866 11.0534 15.6475 11.0528L16.118 11.0492L16.2668 10.6029C16.286 10.5451 16.3677 10.5451 16.387 10.6029Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M16.387 12.9378L16.5359 13.3842L17.0064 13.3878C17.0673 13.3883 17.0925 13.466 17.0436 13.5022L16.6651 13.7817L16.8071 14.2303C16.8255 14.2884 16.7593 14.3365 16.7097 14.301L16.3269 14.0274L15.9442 14.301C15.8946 14.3364 15.8286 14.2884 15.8468 14.2303L15.9888 13.7817L15.6103 13.5022C15.5613 13.4659 15.5866 13.3883 15.6475 13.3878L16.118 13.3842L16.2668 12.9378C16.286 12.8801 16.3677 12.8801 16.387 12.9378Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M16.387 15.2729L16.5359 15.7192L17.0064 15.7228C17.0673 15.7233 17.0925 15.801 17.0436 15.8372L16.6651 16.1168L16.8071 16.5653C16.8255 16.6234 16.7593 16.6715 16.7097 16.636L16.3269 16.3624L15.9442 16.636C15.8946 16.6714 15.8286 16.6234 15.8468 16.5653L15.9888 16.1168L15.6103 15.8372C15.5613 15.801 15.5866 15.7233 15.6475 15.7228L16.118 15.7192L16.2668 15.2729C16.286 15.215 16.3677 15.215 16.387 15.2729Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M16.387 17.6079L16.5359 18.0542L17.0064 18.0578C17.0673 18.0584 17.0925 18.136 17.0436 18.1722L16.6651 18.4518L16.8071 18.9004C16.8255 18.9584 16.7593 19.0065 16.7097 18.971L16.3269 18.6974L15.9442 18.971C15.8946 19.0065 15.8286 18.9584 15.8468 18.9004L15.9888 18.4518L15.6103 18.1722C15.5613 18.136 15.5866 18.0584 15.6475 18.0578L16.118 18.0542L16.2668 17.6079C16.286 17.55 16.3677 17.55 16.387 17.6079Z"
                  fill="#F5F5F5"
                />
              </svg>
              <div>
                <h3 className="text-sm">United States - English</h3>
                <span className="input-titel text-spiroDiscoBall">Dollar</span>
              </div>
            </div>
            <div>
              <input
                type="radio"
                name="select"
                id="selact19"
                className="w-6 h-6 rounded-full bg-brightGray appearance-none hidden"
                onChange={() => {
                  onSelectLanguage("en");
                }}
                checked={selectLanguage === "en"}
              />
              <div className="w-6 h-6 flex items-center justify-center rounded-full bg-brightGray overflow-hidden check-language">
                <i className="icon-right text-[10px] scale-0 origin-center duration-300 transition-all"></i>
              </div>
            </div>
          </label>
          <label
            htmlFor="selact20"
            className="bg-white rounded-md py-3 px-5 flex items-center justify-between cursor-pointer"
          >
            <div className="flex items-start space-x-4">
              <svg
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1_2663)">
                  <path
                    d="M13 6.72459H0.672445C0.301107 6.72459 0 7.0257 0 7.39704V31.6039C0 31.9753 0.301107 32.2764 0.672445 32.2764H13V6.72459Z"
                    fill="#41479B"
                  />
                  <path
                    d="M26.0007 32.2754H13.0005V6.72354H26.0007V32.2754Z"
                    fill="#F5F5F5"
                  />
                  <path
                    d="M38.3276 6.72466H26V32.2764H38.3276C38.6989 32.2764 39 31.9753 39 31.6039V7.39703C39 7.02562 38.6989 6.72466 38.3276 6.72466Z"
                    fill="#FF4B55"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_2663">
                    <rect
                      width="39"
                      height="39"
                      fill="white"
                      transform="matrix(1 0 0 -1 0 39)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <div>
                <h3 className="text-sm">Europe - French</h3>
                <span className="input-titel text-spiroDiscoBall">Euro</span>
              </div>
            </div>
            <div>
              <input
                type="radio"
                name="select"
                id="selact20"
                className="w-6 h-6 rounded-full bg-brightGray appearance-none hidden"
                onChange={() => {
                  onSelectLanguage("fr");
                }}
                checked={selectLanguage === "fr"}
              />
              <div className="w-6 h-6 flex items-center justify-center rounded-full bg-brightGray overflow-hidden check-language">
                <i className="icon-right text-[10px] scale-0 origin-center duration-300 transition-all"></i>
              </div>
            </div>
          </label>
          {/* <label
                        htmlFor="selact21"
                        className="bg-white rounded-md py-3 px-5 flex items-center justify-between cursor-pointer"
                    >
                        <div className="flex items-start space-x-4">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                xmlnsSvgjs="http://svgjs.com/svgjs"
                                width="39"
                                height="39"
                                x="0"
                                y="0"
                                viewBox="0 0 512 512"
                                style={{ enableBackground: "new 0 0 512 512" }}
                                xmlSpace="preserve"
                                className=""
                            >
                                <g>
                                    <rect
                                        xmlns="http://www.w3.org/2000/svg"
                                        y="85.337"
                                        style={{}}
                                        width="512"
                                        height="341.326"
                                        fill="#d80027"
                                        data-original="#d80027"
                                        className=""
                                    ></rect>
                                    <g xmlns="http://www.w3.org/2000/svg">
                                        <polygon
                                            style={{}}
                                            points="247.213,216.787 264.807,241.033 293.3,231.794 275.679,256.018 293.271,280.262    264.787,270.988 247.166,295.213 247.184,265.258 218.7,255.983 247.194,246.744  "
                                            fill="#f0f0f0"
                                            data-original="#f0f0f0"
                                        ></polygon>
                                        <path
                                            style={{}}
                                            d="M199.202,316.602c-33.469,0-60.602-27.133-60.602-60.602s27.133-60.602,60.602-60.602   c10.436,0,20.254,2.639,28.827,7.284c-13.448-13.152-31.84-21.269-52.135-21.269c-41.193,0-74.586,33.394-74.586,74.586   s33.394,74.586,74.586,74.586c20.295,0,38.687-8.117,52.135-21.269C219.457,313.963,209.638,316.602,199.202,316.602z"
                                            fill="#f0f0f0"
                                            data-original="#f0f0f0"
                                        ></path>
                                    </g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                    <g xmlns="http://www.w3.org/2000/svg"></g>
                                </g>
                            </svg>
                            <div>
                                <h3 className="text-sm">Turkish - Turkish</h3>
                                <span className="input-titel text-spiroDiscoBall">
                                    Turkish lira
                                </span>
                            </div>
                        </div>
                        <div>
                            <input
                                type="radio"
                                name="select"
                                id="selact21"
                                className="w-6 h-6 rounded-full bg-brightGray appearance-none hidden"
                            />
                            <div className="w-6 h-6 flex items-center justify-center rounded-full bg-brightGray overflow-hidden check-language">
                                <i className="icon-right text-[10px] scale-0 origin-center duration-300 transition-all"></i>
                            </div>
                        </div>
                    </label> */}
        </div>
      </div>
    </div>
  );
};

export default LanguagePopup;
