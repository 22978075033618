import React from "react";

function Privacy() {
  return (
    <div className="popup table fixed w-full p-0 inset-0 z-50 bg-brightGray bg-opacity-75 h-screen">
      <div className="table-cell align-middle p-0">
        <div className="popin w-full mx-auto max-h-[calc(100vh-55px)] overflow-y-auto">
          <div className="bg-brightGray p-7 space-y-7">
            <div id="termsBox">
              <div className="w-full flex items-center justify-between sticky top-0 mb-5 py-5 bg-brightGray">
                <h2 className="h1 w-full text-center">Privacy Policy</h2>

              </div>
              <div className="space-y-3">
                <p className="text-japaneseIndigo">Privacy Policy</p>
                <p className="text-japaneseIndigo mt-none">Updated: May 28, 2024</p>
                &nbsp;
                <p className="text-japaneseIndigo">Evento Package Private Limited</p>
                <p className="text-sm font-normal">
                  Protecting your privacy is important to us. We collects your information in order to provide you with better service and user experience. This Privacy Policy contains important information on how and why we collect, store, use, and share your personal information. It also explains your rights in relation to your personal information and how to contact us. Before using or continuing to use our products and services, please read carefully and fully understand this policy.
                  We do not collect personal information which personally identifies you, such as your name, email address or billing information, or other data which can be reasonably linked to such information. However, We may use personal information submitted by you only for the following purposes: help us develop, deliver, and improve our products and services and supply higher quality service; manage online surveys and other activities you’ve participated in.
                </p>
                <p className="text-sm font-normal">For more detailed information on the above, please refer to the relevant sections in the following index：</p>
              </div>
              <p className="text-japaneseIndigo">1. Key permissions and information we use</p>
              <p className="text-japaneseIndigo">2. Protection Information Security</p>
              <p className="text-japaneseIndigo">3. Share Your Personal Information</p>
              <p className="text-japaneseIndigo">4. Transfer your personal information</p>
              <p className="text-japaneseIndigo">5. How we Publicly disclose your personal information</p>
              <p className="text-japaneseIndigo">6. Your Rights</p>
              <p className="text-japaneseIndigo">7. Protection of Children</p>
              <p className="text-japaneseIndigo">8. Changes to this policy</p>
              <p className="text-japaneseIndigo">9. Governing Law</p>
              <p className="text-japaneseIndigo">10. Contact Us</p>

              <h3 className="text-japaneseIndigo py-3">1. Key permissions and information we use</h3>
              <div className="space-y-3">
                <ul className="list-disc list-inside">
                  <li>
                    <strong>Read your contacts</strong>
                    <p className="text-sm font-normal">When the phone receives an incoming call, you need to read the phone contact to ensure that the application can be used normally.</p>
                    <p className="text-sm font-normal"><strong>Purpose : When the phone calls, the screen of the call show can be displayed normally.</strong></p>
                  </li>

                  <li className="py-3">
                    <strong>MANAGE_EXTERNAL_STORAGE</strong>
                    <p className="text-sm font-normal">We are using this permission because we are providing user to hide their personal data like photos, videos and documents and also we are providing to user to find duplicate photos and videos from their mobile storage and external storage, So because of this core functinality we need</p>
                    <p className="text-sm font-normal">MANAGE_EXTERNAL_STORAGE Permission.</p>
                  </li>

                  <li className="py-3">
                    <strong>Camera</strong>
                    <p className="text-sm font-normal">When you want to use the flash function, you will be asked to turn on the camera permission. The camera permission is to increase the flash when you call.</p>
                    <p className="text-sm font-normal"><strong>Purpose : Turn ON/OFF Camera Flash.</strong></p>
                  </li>

                  <li className="py-3">
                    <strong>Read phone status</strong>
                    <p className="text-sm font-normal">When you receive an incoming call, you can judge the incoming call status by identifying the mobile phone status identification, and display the call flash you set.</p>
                    <p className="text-sm font-normal"><strong>Purpose : Identify phone incoming call status</strong></p>
                  </li>

                  <li className="py-3">
                    <strong>SMS and Call Log Permissions</strong>
                    <p className="text-sm font-normal">When you receive an incoming call, you can judge the incoming call status by identifying the mobile phone status identification, and display the call flash you set.</p>
                    <p className="text-sm font-normal"><strong>Purpose : Identify phone incoming/outgoing call status</strong></p>
                  </li>

                  <li className="py-3">
                    <strong>READ_PRECISE_PHONE_STATE Permissions</strong>
                    <p className="text-sm font-normal">To improve your dialer, set a dialer to make default and amazing experience.</p>
                  </li>

                  <li className="py-3">
                    <strong>AccessibilityService Api Permissions</strong>
                    <p className="text-sm font-normal">Accessibility services should only be used for handle receive callbacks.</p>
                    <p className="text-sm font-normal"><strong>Purpose : Get to change your default screen to attractive dialer screen.</strong></p>
                  </li>

                  <li className="py-3">
                    <strong>Third-Party Analytics related</strong>
                    <p className="text-sm font-normal">To improve our products and services we may use third-party analytics tools such as Google Firebase Analytics /Flurry to help us analyze usage trends for the services. These tools will track the information on how you use our App. In such cases, we will only process anonymized data.</p>
                    <p className="text-sm font-normal"><strong>Purpose: To improve products and services by conducting statistical analysis or other reserch activities to optimieze the features and provide you with new function.</strong></p>
                  </li>

                  <li className="py-3">
                    <strong>Third-Party Advertising related</strong>
                    <p className="text-sm font-normal">We may allow the third-party online advertising network such as Google Admob and Facebook ads to collect information about your use of our App over time for playing or displaying ads that may be of interest to you.</p>
                    <p className="text-sm font-normal">To personalize the content of promotional communications relating to products and services offered by third parties on Google AdMob. You can find more information on how Google AdMob processes your personal data by visiting Google’s Privacy & Terms.</p>
                  </li>

                  <li className="py-3">
                    <strong>Third Part Policy list</strong>
                    <p className="text-sm font-normal">The app may include other websites or services operated by third parties. The activities described in this Privacy Policy do not apply to data processed by such third-party websites and services. We have no control over, and we are not responsible for, the actions and privacy policies of third parties and other websites and services.</p>
                    <table className="table-auto border border-gray-800 border-separate">
                      <tbody>

                      <tr className="border border-gray-800">
                        <th colSpan="2" scope="col"><p className="text-sm font-bold text-center">Advertisement platform - To get and display ads</p></th>
                      </tr>
                      <tr className="border border-gray-800">
                        <td width="145"><p className="text-sm font-normal">Admob</p></td>
                        <td width="501"><p className="text-sm font-normal">https://policies.google.com/privacy</p></td>
                      </tr>
                      <tr className="border border-gray-800">
                        <td><p className="text-sm font-normal">Facebook</p></td>
                        <td><p className="text-sm font-normal">https://www.facebook.com/about/privacy</p></td>
                      </tr>
                      <tr className="border border-gray-800">
                        <td><p className="text-sm font-normal">MoPub</p></td>
                        <td><p className="text-sm font-normal">https://www.mopub.com/en/legal/privacy</p></td>
                      </tr>
                      <tr className="border border-gray-800">
                        <td><p className="text-sm font-normal">Inmobi</p></td>
                        <td><p className="text-sm font-normal">https://www.inmobi.com/privacy-policy</p></td>
                      </tr>
                      <tr>
                        <td><p className="text-sm font-normal">A4G</p></td>
                        <td><p className="text-sm font-normal">https://www.a4g.com/privacy</p></td>
                      </tr>
                      <tr className="border border-gray-800">
                        <td><p className="text-sm font-normal">Smaato</p></td>
                        <td><p className="text-sm font-normal">https://www.smaato.com/privacy/</p></td>
                      </tr>
                      <tr className="border border-gray-800">
                        <td><p className="text-sm font-normal">Applovin</p></td>
                        <td><p className="text-sm font-normal">https://www.applovin.com/privacy/</p></td>
                      </tr>
                      <tr className="border border-gray-800">
                        <th colSpan="2" scope="col"><p className="text-sm font-bold text-center">Analytics platform -To Analyze application date</p></th>
                      </tr>
                      <tr className="border border-gray-800">
                        <td><p className="text-sm font-normal">Google Analytics</p></td>
                        <td><p className="text-sm font-normal">https://www.google.com/analytics/terms/us.html/</p></td>
                      </tr>
                      <tr className="border border-gray-800">
                        <td><p className="text-sm font-normal">Firebase</p></td>
                        <td><p className="text-sm font-normal">https://firebase.google.com/terms/</p></td>
                      </tr>
                      <tr className="border border-gray-800">
                        <td><p className="text-sm font-normal">Flurry</p></td>
                        <td><p className="text-sm font-normal">https://legal.yahoo.com/us/en/yahoo/privacy/index.htm</p></td>
                      </tr>
                      </tbody>
                    </table>
                  </li>

                  <li className="py-3">
                    <strong>Information We Use</strong>
                    <p className="text-sm font-normal">We provide you with a set of Services that we improve with the information we collect:</p>
                    <p className="text-sm font-normal">(1) Provide and deliver the products, services, and functionality that you request, process transactions, and send you related information;</p>
                    <p className="text-sm font-normal">(2) Monitor and analyze trends and usage;</p>
                    <p className="text-sm font-normal">(3) Develop, improve, and refine products, Services, and functionality;</p>
                    <p className="text-sm font-normal">(4) Personalize the Services by providing advertisements, content, or features that match user profiles or interests</p>
                  </li>
                </ul>
              </div>
              <h3 className="text-japaneseIndigo py-3">
                2. Protection Information Security
              </h3>
              <div className="space-y-3">
                <p className="text-sm font-normal">
                  We will not collect, access, store and use your private content and sensitive permissions without your permission. We will work hard to provide protection for your information security to prevent information loss, improper use, unauthorized access or disclosure.
                </p>
                <p className="text-sm font-normal">
                  In general, we will keep your personal information for the length of time needed to fulfill any of the applicable purposes of use described in this Privacy Policy; We will take reasonable measures to prevent the loss, improper use of, unauthorized access to or disclosure of information. Some of our Services will use encryption techniques to protect your personal details. However, you understand and accept that even though we will take all possible security measures, we cannot always guarantee that your information is 100% secure. Hope you understand and accept that the system and communication network used by you to access our Services may fail due to factors beyond our control.
                </p>

              </div>
              <h3 className="text-japaneseIndigo py-3">
                3. Share Your Personal Information
              </h3>
              <div className="space-y-3">
                <p className="text-sm font-normal">
                  We promise to keep your personal information confidential. Except for the scenarios stipulated in this policy, We may share or disclose your personal data to the following categories of recipients:
                </p>
                <p className="text-sm font-normal">
                  Have obtained your explicit consent or authorization;
                </p>
                <p className="text-sm font-normal">
                  Public, judicial or police authorities, within the limits established by applicable laws.
                </p>
              </div>
              <h3 className="text-japaneseIndigo py-3">4. Transfer your personal information</h3>
              <div className="space-y-3">
                <p className="text-sm font-normal">
                  Except for the requirements of laws and regulations, mandatory administrative law enforcement, judicial or regulatory requirements, we will not transfer your personal information to any company, organization or individual.
                </p>
                <p className="text-sm font-normal">
                  However If we carry out a corporate transaction or operation (for example, in case of bankruptcy, merger, acquisition, reorganization, sale of assets or assignments, and due diligence related to any such transactions), your personal data may be disclosed to our advisers and any prospective purchaser's advisers, and may be one of the assets that is transferred to another owner.
                </p>
              </div>
              <h3 className="text-japaneseIndigo py-3">
                5. How we Publicly disclose your personal information
              </h3>
              <div className="space-y-3">
                <p className="text-sm font-normal">
                  We will only publicly disclose your personal information in the following circumstances:
                </p>
                <ul className="list-disc list-inside">
                  <li className="text-sm font-normal">
                    When the phone receives an incoming call, you need to read the phone contact to ensure that the application can be used normally.
                  </li>
                  <li className="text-sm font-normal">
                    According to the requirements of laws, regulations, mandatory administrative law enforcement, judicial or regulatory agencies, where it is necessary to provide your personal information, we may publicly disclose your personal information based on the type of personal information requested and the method of disclosure.
                  </li>
                </ul>
              </div>
              <h3 className="text-japaneseIndigo py-3">
                6. Your Rights
              </h3>
              <div className="space-y-3">
                <p className="text-sm font-normal">Under applicable laws of your jurisdiction, you may have one or more of the rights to your personal information. These rights may include : </p>
                <ul className="list-disc list-inside">
                  <li className="text-sm font-normal">
                    Provide you with information we collect about you.
                  </li>
                  <li className="text-sm font-normal">
                    Device permissions.You may revoke any permissions you have granted to us before.
                  </li>
                  <li className="text-sm font-normal">
                    Delete; You have the right to request the deletion of your personal data. and you can delete the personal data from your photo setting function (include app user data/ storge / cache)
                  </li>
                  <li className="text-sm font-normal">
                    You have the right to obtain information about the processing of your personal data and to access it.
                  </li>
                </ul>
                <p className="text-sm font-normal">Right to withdraw your consent. Where we rely on your consent to process your personal data, you have the right to withdraw your consent.</p>
              </div>
              <h3 className="text-japaneseIndigo py-3">7. Protection of Children</h3>
              <div className="space-y-3">
                <p className="text-sm font-normal">
                  The app is not intended for anyone under the age of 13 ,We respect the privacy of children under the terms of Privacy Policy. We do not knowingly collect or solicit any information from anyone under the age of 13 or knowingly allow such persons to register for the Service. The Service and its content are not directed at children under the age of 13. In the event that we learn that we have collected personal information from a child under age 13 without parental consent, we will delete that information as quickly as possible. If you believe that we might have any information from or about a child under 13, please contact us with email.
                </p>
              </div>
              <h3 className="text-japaneseIndigo py-3">8. Changes to this policy</h3>
              <div className="space-y-3">
                <p className="text-sm font-normal">
                  We may modify or update this Privicy Policy and We will not reduce your rights under this Privacy Policy without your explicit consent. We will post any Privacy Policy changes on this page.
                </p>
              </div>
              <h3 className="text-japaneseIndigo py-3">9. Governing Law</h3>
              <div className="space-y-3">
                <ul className="list-disc list-inside">
                  <li className="text-sm font-normal">
                    <strong>European Union and UK residents</strong>
                    <p className="text-sm font-normal pb-2">
                      If you reside in the UK or another member state of the European Union, you have certain rights under EU data protection legislation. You can ask us to:
                    </p>
                    <p className="text-sm font-normal">1) Confirm we process your personal data, and ask what personal data we have about you.</p>
                    <p className="text-sm font-normal">2) Change or fix your data.</p>
                    <p className="text-sm font-normal">3) Delete your data.</p>
                    <p className="text-sm font-normal">4) Stop using some or all of your data if we have no legal right to keep using it. </p>
                  </li>
                  <li className="text-sm font-normal mt-4">
                    <strong>California resident</strong>
                    <p className="text-sm font-normal pb-2">If you are a California resident, you have the following rights under the California Consumer Privacy Act and California Privacy Rights Act.
                      You have the right to know what personal information we have collected about you in the preceding 12 months, including sources, purpose of collection, and third parties we have shared it with.</p>
                    <p className="text-sm font-normal">1) You can ask us to delete your personal information</p>
                    <p className="text-sm font-normal">2) You can ask us not to share or sell your information with any third party.</p>
                    <p className="text-sm font-normal">3) We do not sell your personal information. We do allow our advertising partners to collect certain device identifiers and electronic network activity via our app to show ads that are based on your interests. If you prefer to limit this activity, your device may include a feature'Opt Out of Interest-Based Ads' or 'Opt Out of Ads Personalization' on Android) that allows you to opt out of having certain information collected through mobile apps used for interest-based advertising purposes.</p>
                  </li>
                </ul>
              </div>
              <h3 className="text-japaneseIndigo py-3">10. Contact Us</h3>
              <div className="space-y-3">
                <p className="text-sm font-normal">
                  If you have questions about the privacy aspects of our Services or would like to make a complaint, please contact at <strong>[help@eventopackage.com]</strong>. Please be sure to include your name, address and email address in any correspondence to us so that we can respond to your inquiry in a timely manner.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
