import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getProfileDetails, useProfileDetails } from "../Profile/profileSlice";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
  EmailIcon,
  TelegramIcon,
  TwitterIcon,
  LinkedinIcon,
} from "react-share";
import copy from "../../assest/images/Clip.png";

const ReferToEarn = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const profileDetails = useProfileDetails();

  const getProfile = async () => {
    try {
      await dispatch(getProfileDetails()).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);
  // profileDetails.my_refer_code
  const copyCode = () => {
    navigator.clipboard.writeText(`Give 20 Coin, Get 10 Coin
    Refer Friends to allset and give them 10 coin with your referral code ${profileDetails?.my_refer_code} once they order, you get 20 coin too. www.eventopackage.com`);
    toast.success(`${intl.formatMessage({ id: "REFERRAL CODE COPIED" })}`);
  };

  const size = 50;

  const url = `Give 10 Coin, Get 10 Coin
  Refer Friends to allset and give them 10 coin with your referral code ${profileDetails?.my_refer_code} once they order, you get 10 coin too. www.eventopackage.com`;

  return (
    <div className="wrapper min-h-full">
      <div className="space-y-7 h-full">
        {/* <!-- title-holder  --> */}
        <h1>
          <span>{intl.formatMessage({ id: "REFER TO EARN" })}</span>
        </h1>
        <div className="p-7 max-[768px]:p-4 bg-white rounded-md">
          <div className="py-3.5 px-5 rounded-lg bg-spiroDiscoBall flex items-center justify-between">
            <svg
              width="62"
              height="62"
              viewBox="0 0 62 62"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M44.7295 16.7127V22.4561C44.7295 24.0224 43.4598 25.2921 41.8935 25.2921H7.92976C6.36342 25.2921 5.09375 24.0224 5.09375 22.4561V16.7127C5.09375 15.1476 6.36342 13.8779 7.92976 13.8779H41.8934C43.4598 13.8779 44.7295 15.1476 44.7295 16.7127Z"
                fill="#DD9F3A"
              />
              <path
                d="M39.6357 28.1278V33.8711C39.6357 35.4375 38.3661 36.7071 36.7997 36.7071H2.83601C1.26967 36.7071 0 35.4375 0 33.8711V28.1278C0 26.5626 1.26967 25.293 2.83601 25.293H36.7996C38.3661 25.293 39.6357 26.5626 39.6357 28.1278Z"
                fill="#DD9F3A"
              />
              <path
                d="M41.5459 39.5428V45.2862C41.5459 46.8525 40.2762 48.1222 38.7099 48.1222H4.74617C3.17982 48.1222 1.91016 46.8525 1.91016 45.2862V39.5428C1.91016 37.9777 3.17982 36.708 4.74617 36.708H38.7098C40.2762 36.708 41.5459 37.9777 41.5459 39.5428Z"
                fill="#DD9F3A"
              />
              <path
                d="M39.6357 50.9578V56.7012C39.6357 58.2675 38.3661 59.5372 36.7997 59.5372H2.83601C1.26967 59.5372 0 58.2675 0 56.7012V50.9578C0 49.3927 1.26967 48.123 2.83601 48.123H36.7996C38.3661 48.123 39.6357 49.3927 39.6357 50.9578Z"
                fill="#DD9F3A"
              />
              <path
                d="M39.636 50.9578V54.9183C39.636 56.3828 38.5256 57.5888 37.1006 57.7391C37.0013 57.7493 36.9018 57.7543 36.8 57.7543H9.81625C8.25111 57.7543 6.98145 56.4847 6.98145 54.9183V50.9578C6.98145 49.3927 8.25111 48.123 9.81625 48.123H36.8C38.3664 48.123 39.636 49.3927 39.636 50.9578Z"
                fill="#F4B74A"
              />
              <path
                d="M39.636 50.9578V54.9183C39.636 56.3828 38.5256 57.5888 37.1006 57.7391C37.0013 57.7493 36.9018 57.7543 36.8 57.7543H9.81625C8.25111 57.7543 6.98145 56.4847 6.98145 54.9183V50.9578C6.98145 49.3927 8.25111 48.123 9.81625 48.123H36.8C38.3664 48.123 39.636 49.3927 39.636 50.9578Z"
                fill="#F4B74A"
              />
              <path
                d="M39.6355 39.5423V43.5028C39.6355 44.9673 38.5251 46.1733 37.1001 46.3236C37.0008 46.3337 36.9013 46.3388 36.7995 46.3388H9.81575C8.25062 46.3388 6.98096 45.0691 6.98096 43.5028V39.5423C6.98096 37.9772 8.25062 36.7075 9.81575 36.7075H36.7995C38.366 36.7074 39.6355 37.9771 39.6355 39.5423Z"
                fill="#F4B74A"
              />
              <path
                d="M39.6355 28.1283V32.0887C39.6355 33.5532 38.5251 34.7592 37.1001 34.9095C37.0008 34.9197 36.9013 34.9248 36.7995 34.9248H9.81575C8.25062 34.9248 6.98096 33.6551 6.98096 32.0887V28.1283C6.98096 26.5631 8.25062 25.2935 9.81575 25.2935H36.7995C38.366 25.2935 39.6355 26.5631 39.6355 28.1283Z"
                fill="#F4B74A"
              />
              <path
                d="M44.7293 16.7127V20.6732C44.7293 22.1377 43.6188 23.3437 42.1938 23.494C42.0945 23.5041 41.9951 23.5092 41.8933 23.5092H14.9095C13.3444 23.5092 12.0747 22.2396 12.0747 20.6732V16.7127C12.0747 15.1476 13.3444 13.8779 14.9095 13.8779H41.8933C43.4597 13.8779 44.7293 15.1476 44.7293 16.7127Z"
                fill="#F4B74A"
              />
              <path
                d="M39.6361 50.9583V56.7017C39.6361 58.1662 38.5257 59.3722 37.1006 59.5224C36.8421 59.5326 36.5836 59.5377 36.325 59.5377C33.5846 59.5377 30.844 58.9735 28.2919 57.8427C26.5791 57.0851 24.9541 56.0714 23.4693 54.8043C23.072 54.4656 22.6874 54.1089 22.3117 53.7345C20.6346 52.0574 19.3216 50.1548 18.3716 48.1235H36.8001C38.3664 48.1235 39.6361 49.3932 39.6361 50.9583Z"
                fill="#DD9F3A"
              />
              <path
                d="M39.6363 39.5428V45.2861C39.6363 46.8525 38.3666 48.1222 36.8003 48.1222H20.2795C18.6023 44.5373 18.0573 40.5526 18.6455 36.708H36.8003C38.3666 36.7079 39.6363 37.9777 39.6363 39.5428Z"
                fill="#DD9F3A"
              />
              <path
                d="M39.6357 5.29769V11.041C39.6357 12.6074 38.3661 13.877 36.7997 13.877H2.83601C1.26967 13.8772 0 12.6075 0 11.0412V5.29769C0 3.73256 1.26967 2.46289 2.83601 2.46289H36.7996C38.3661 2.46301 39.6357 3.73268 39.6357 5.29769Z"
                fill="#DD9F3A"
              />
              <path
                d="M39.636 5.2972V9.25769C39.636 10.7222 38.5256 11.9282 37.1006 12.0784C37.0013 12.0886 36.9018 12.0937 36.8 12.0937H9.81625C8.25111 12.0937 6.98145 10.824 6.98145 9.25769V5.2972C6.98145 3.73207 8.25111 2.4624 9.81625 2.4624H36.8C38.3664 2.46252 39.636 3.73219 39.636 5.2972Z"
                fill="#F4B74A"
              />
              <path
                d="M34.9737 2.4624H30.8145V12.0937H34.9737V2.4624Z"
                fill="#F9C662"
              />
              <path
                d="M27.7578 2.4624H25.6782V12.0937H27.7578V2.4624Z"
                fill="#F9C662"
              />
              <path
                d="M39.9839 13.8784H35.8242V25.2939H39.9839V13.8784Z"
                fill="#F9C662"
              />
              <path
                d="M32.7669 13.8784H30.687V25.2939H32.7669V13.8784Z"
                fill="#F9C662"
              />
              <path
                d="M44.7297 20.6008V22.4563C44.7297 24.0227 43.46 25.2923 41.8936 25.2923H25.9229C31.1047 20.407 38.2744 18.8434 44.7297 20.6008Z"
                fill="#DD9F3A"
              />
              <path
                d="M39.6358 28.1283V33.8716C39.6358 35.4379 38.3662 36.7076 36.7998 36.7076H16.7349C17.3499 32.6809 19.2093 28.8082 22.3101 25.7074C22.4514 25.566 22.5941 25.4285 22.7381 25.2935H36.7998C38.3662 25.2935 39.6358 26.5631 39.6358 28.1283Z"
                fill="#DD9F3A"
              />
              <path
                d="M56.1959 53.7334C50.2347 59.6936 41.4223 61.0637 34.1501 57.842C32.4373 57.0841 30.8115 56.0714 29.3274 54.804C28.93 54.4656 28.5444 54.108 28.1689 53.7334C20.43 45.9936 20.43 33.4463 28.1689 25.7065C35.9087 17.9676 48.4561 17.9676 56.1959 25.7065C56.5714 26.082 56.9279 26.4675 57.2665 26.865C58.5338 28.348 59.5465 29.9748 60.3045 31.6876C63.5262 38.9589 62.156 47.7724 56.1959 53.7334Z"
                fill="#F4B74A"
              />
              <path
                d="M60.3046 31.6874L34.1503 57.8418C32.4376 57.0839 30.8118 56.0712 29.3276 54.8038L57.2667 26.8647C58.5339 28.3478 59.5466 29.9747 60.3046 31.6874Z"
                fill="#F9C662"
              />
              <path
                d="M56.196 39.7198C56.196 47.4592 49.9219 53.7332 42.1827 53.7332C37.814 53.7332 33.9129 51.7347 31.3424 48.6012C29.3589 46.1837 28.1694 43.0906 28.1694 39.7198C28.1694 31.9805 34.4435 25.7065 42.1827 25.7065C45.5542 25.7065 48.6474 26.8977 51.0648 28.8814C54.1975 31.4507 56.196 35.3521 56.196 39.7198Z"
                fill="#FFD488"
              />
              <path
                d="M51.065 28.8814L31.3443 48.6021L31.3424 48.6012C30.7635 47.8959 30.2522 47.1324 29.8192 46.3215C29.3255 45.4 28.9329 44.4179 28.6544 43.3874C28.3382 42.2183 28.1694 40.9883 28.1694 39.7199C28.1694 31.9805 34.4435 25.7065 42.1827 25.7065C43.4522 25.7065 44.682 25.8751 45.8503 26.1915C46.8808 26.47 47.8629 26.8636 48.7844 27.3573C49.5954 27.7904 50.3587 28.3025 51.065 28.8814Z"
                fill="#F9C662"
              />
              <path
                d="M48.7843 27.3572L29.8191 46.3215C29.3254 45.4 28.9328 44.4179 28.6543 43.3874L45.8503 26.1914C46.8806 26.4699 47.8628 26.8635 48.7843 27.3572Z"
                fill="#FFD488"
              />
              <path
                d="M43.138 38.8413V33.5815C44.5305 33.906 45.5495 34.9203 45.5495 36.1187C45.5495 36.6462 45.977 37.0738 46.5046 37.0738C47.0322 37.0738 47.4597 36.6462 47.4597 36.1187C47.4597 33.8878 45.593 32.0277 43.1378 31.6384V31.0766C43.1378 30.5492 42.7104 30.1216 42.1828 30.1216C41.6552 30.1216 41.2277 30.5492 41.2277 31.0766V31.6384C38.7725 32.0278 36.9058 33.888 36.9058 36.1187C36.9058 38.3495 38.7725 40.2098 41.2277 40.5991V45.8589C39.8351 45.5344 38.816 44.5201 38.816 43.3216C38.816 42.7941 38.3886 42.3666 37.8609 42.3666C37.3333 42.3666 36.9059 42.7941 36.9059 43.3216C36.9059 45.5525 38.7727 47.4127 41.2278 47.802V48.3637C41.2278 48.8912 41.6553 49.3188 42.1829 49.3188C42.7105 49.3188 43.138 48.8912 43.138 48.3637V47.802C45.5931 47.4127 47.4598 45.5524 47.4598 43.3216C47.4598 41.0908 45.5931 39.2307 43.138 38.8413ZM38.8161 36.1186C38.8161 34.9202 39.8351 33.9059 41.2278 33.5813V38.6559C39.8353 38.3315 38.8161 37.3171 38.8161 36.1186ZM43.138 45.8589V40.7844C44.5305 41.1089 45.5495 42.1232 45.5495 43.3216C45.5495 44.5201 44.5305 45.5344 43.138 45.8589Z"
                fill="#E0A33F"
              />
              <path
                d="M42.1828 54.6885C37.6819 54.6885 33.4617 52.6907 30.604 49.207C28.4183 46.5431 27.2144 43.1738 27.2144 39.72C27.2144 31.4663 33.9292 24.7515 42.1828 24.7515C45.6357 24.7515 49.0053 25.956 51.6709 28.1429C55.1538 30.9998 57.1512 35.2194 57.1512 39.7198C57.1511 47.9736 50.4365 54.6885 42.1828 54.6885ZM42.1828 26.6617C34.9825 26.6617 29.1246 32.5196 29.1246 39.72C29.1246 42.733 30.1745 45.6721 32.0809 47.9954C34.5742 51.0349 38.2561 52.7782 42.1828 52.7782C49.3831 52.7782 55.241 46.9203 55.241 39.72C55.241 35.7939 53.4981 32.1125 50.4592 29.6199C48.1345 27.7122 45.195 26.6617 42.1828 26.6617Z"
                fill="#E0A33F"
              />
            </svg>
            <h1 className="text-white">
              <span className="font-normal">
                {intl.formatMessage({ id: "TOTAL REWARDS EARNED :" })}
              </span>{" "}
              {profileDetails?.f_coin}
              <span>{intl.formatMessage({ id: "F-COINS" })}</span>
            </h1>
            <svg
              width="62"
              height="62"
              viewBox="0 0 62 62"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M44.7295 16.7127V22.4561C44.7295 24.0224 43.4598 25.2921 41.8935 25.2921H7.92976C6.36342 25.2921 5.09375 24.0224 5.09375 22.4561V16.7127C5.09375 15.1476 6.36342 13.8779 7.92976 13.8779H41.8934C43.4598 13.8779 44.7295 15.1476 44.7295 16.7127Z"
                fill="#DD9F3A"
              />
              <path
                d="M39.6357 28.1278V33.8711C39.6357 35.4375 38.3661 36.7071 36.7997 36.7071H2.83601C1.26967 36.7071 0 35.4375 0 33.8711V28.1278C0 26.5626 1.26967 25.293 2.83601 25.293H36.7996C38.3661 25.293 39.6357 26.5626 39.6357 28.1278Z"
                fill="#DD9F3A"
              />
              <path
                d="M41.5459 39.5428V45.2862C41.5459 46.8525 40.2762 48.1222 38.7099 48.1222H4.74617C3.17982 48.1222 1.91016 46.8525 1.91016 45.2862V39.5428C1.91016 37.9777 3.17982 36.708 4.74617 36.708H38.7098C40.2762 36.708 41.5459 37.9777 41.5459 39.5428Z"
                fill="#DD9F3A"
              />
              <path
                d="M39.6357 50.9578V56.7012C39.6357 58.2675 38.3661 59.5372 36.7997 59.5372H2.83601C1.26967 59.5372 0 58.2675 0 56.7012V50.9578C0 49.3927 1.26967 48.123 2.83601 48.123H36.7996C38.3661 48.123 39.6357 49.3927 39.6357 50.9578Z"
                fill="#DD9F3A"
              />
              <path
                d="M39.636 50.9578V54.9183C39.636 56.3828 38.5256 57.5888 37.1006 57.7391C37.0013 57.7493 36.9018 57.7543 36.8 57.7543H9.81625C8.25111 57.7543 6.98145 56.4847 6.98145 54.9183V50.9578C6.98145 49.3927 8.25111 48.123 9.81625 48.123H36.8C38.3664 48.123 39.636 49.3927 39.636 50.9578Z"
                fill="#F4B74A"
              />
              <path
                d="M39.636 50.9578V54.9183C39.636 56.3828 38.5256 57.5888 37.1006 57.7391C37.0013 57.7493 36.9018 57.7543 36.8 57.7543H9.81625C8.25111 57.7543 6.98145 56.4847 6.98145 54.9183V50.9578C6.98145 49.3927 8.25111 48.123 9.81625 48.123H36.8C38.3664 48.123 39.636 49.3927 39.636 50.9578Z"
                fill="#F4B74A"
              />
              <path
                d="M39.6355 39.5423V43.5028C39.6355 44.9673 38.5251 46.1733 37.1001 46.3236C37.0008 46.3337 36.9013 46.3388 36.7995 46.3388H9.81575C8.25062 46.3388 6.98096 45.0691 6.98096 43.5028V39.5423C6.98096 37.9772 8.25062 36.7075 9.81575 36.7075H36.7995C38.366 36.7074 39.6355 37.9771 39.6355 39.5423Z"
                fill="#F4B74A"
              />
              <path
                d="M39.6355 28.1283V32.0887C39.6355 33.5532 38.5251 34.7592 37.1001 34.9095C37.0008 34.9197 36.9013 34.9248 36.7995 34.9248H9.81575C8.25062 34.9248 6.98096 33.6551 6.98096 32.0887V28.1283C6.98096 26.5631 8.25062 25.2935 9.81575 25.2935H36.7995C38.366 25.2935 39.6355 26.5631 39.6355 28.1283Z"
                fill="#F4B74A"
              />
              <path
                d="M44.7293 16.7127V20.6732C44.7293 22.1377 43.6188 23.3437 42.1938 23.494C42.0945 23.5041 41.9951 23.5092 41.8933 23.5092H14.9095C13.3444 23.5092 12.0747 22.2396 12.0747 20.6732V16.7127C12.0747 15.1476 13.3444 13.8779 14.9095 13.8779H41.8933C43.4597 13.8779 44.7293 15.1476 44.7293 16.7127Z"
                fill="#F4B74A"
              />
              <path
                d="M39.6361 50.9583V56.7017C39.6361 58.1662 38.5257 59.3722 37.1006 59.5224C36.8421 59.5326 36.5836 59.5377 36.325 59.5377C33.5846 59.5377 30.844 58.9735 28.2919 57.8427C26.5791 57.0851 24.9541 56.0714 23.4693 54.8043C23.072 54.4656 22.6874 54.1089 22.3117 53.7345C20.6346 52.0574 19.3216 50.1548 18.3716 48.1235H36.8001C38.3664 48.1235 39.6361 49.3932 39.6361 50.9583Z"
                fill="#DD9F3A"
              />
              <path
                d="M39.6363 39.5428V45.2861C39.6363 46.8525 38.3666 48.1222 36.8003 48.1222H20.2795C18.6023 44.5373 18.0573 40.5526 18.6455 36.708H36.8003C38.3666 36.7079 39.6363 37.9777 39.6363 39.5428Z"
                fill="#DD9F3A"
              />
              <path
                d="M39.6357 5.29769V11.041C39.6357 12.6074 38.3661 13.877 36.7997 13.877H2.83601C1.26967 13.8772 0 12.6075 0 11.0412V5.29769C0 3.73256 1.26967 2.46289 2.83601 2.46289H36.7996C38.3661 2.46301 39.6357 3.73268 39.6357 5.29769Z"
                fill="#DD9F3A"
              />
              <path
                d="M39.636 5.2972V9.25769C39.636 10.7222 38.5256 11.9282 37.1006 12.0784C37.0013 12.0886 36.9018 12.0937 36.8 12.0937H9.81625C8.25111 12.0937 6.98145 10.824 6.98145 9.25769V5.2972C6.98145 3.73207 8.25111 2.4624 9.81625 2.4624H36.8C38.3664 2.46252 39.636 3.73219 39.636 5.2972Z"
                fill="#F4B74A"
              />
              <path
                d="M34.9737 2.4624H30.8145V12.0937H34.9737V2.4624Z"
                fill="#F9C662"
              />
              <path
                d="M27.7578 2.4624H25.6782V12.0937H27.7578V2.4624Z"
                fill="#F9C662"
              />
              <path
                d="M39.9839 13.8784H35.8242V25.2939H39.9839V13.8784Z"
                fill="#F9C662"
              />
              <path
                d="M32.7669 13.8784H30.687V25.2939H32.7669V13.8784Z"
                fill="#F9C662"
              />
              <path
                d="M44.7297 20.6008V22.4563C44.7297 24.0227 43.46 25.2923 41.8936 25.2923H25.9229C31.1047 20.407 38.2744 18.8434 44.7297 20.6008Z"
                fill="#DD9F3A"
              />
              <path
                d="M39.6358 28.1283V33.8716C39.6358 35.4379 38.3662 36.7076 36.7998 36.7076H16.7349C17.3499 32.6809 19.2093 28.8082 22.3101 25.7074C22.4514 25.566 22.5941 25.4285 22.7381 25.2935H36.7998C38.3662 25.2935 39.6358 26.5631 39.6358 28.1283Z"
                fill="#DD9F3A"
              />
              <path
                d="M56.1959 53.7334C50.2347 59.6936 41.4223 61.0637 34.1501 57.842C32.4373 57.0841 30.8115 56.0714 29.3274 54.804C28.93 54.4656 28.5444 54.108 28.1689 53.7334C20.43 45.9936 20.43 33.4463 28.1689 25.7065C35.9087 17.9676 48.4561 17.9676 56.1959 25.7065C56.5714 26.082 56.9279 26.4675 57.2665 26.865C58.5338 28.348 59.5465 29.9748 60.3045 31.6876C63.5262 38.9589 62.156 47.7724 56.1959 53.7334Z"
                fill="#F4B74A"
              />
              <path
                d="M60.3046 31.6874L34.1503 57.8418C32.4376 57.0839 30.8118 56.0712 29.3276 54.8038L57.2667 26.8647C58.5339 28.3478 59.5466 29.9747 60.3046 31.6874Z"
                fill="#F9C662"
              />
              <path
                d="M56.196 39.7198C56.196 47.4592 49.9219 53.7332 42.1827 53.7332C37.814 53.7332 33.9129 51.7347 31.3424 48.6012C29.3589 46.1837 28.1694 43.0906 28.1694 39.7198C28.1694 31.9805 34.4435 25.7065 42.1827 25.7065C45.5542 25.7065 48.6474 26.8977 51.0648 28.8814C54.1975 31.4507 56.196 35.3521 56.196 39.7198Z"
                fill="#FFD488"
              />
              <path
                d="M51.065 28.8814L31.3443 48.6021L31.3424 48.6012C30.7635 47.8959 30.2522 47.1324 29.8192 46.3215C29.3255 45.4 28.9329 44.4179 28.6544 43.3874C28.3382 42.2183 28.1694 40.9883 28.1694 39.7199C28.1694 31.9805 34.4435 25.7065 42.1827 25.7065C43.4522 25.7065 44.682 25.8751 45.8503 26.1915C46.8808 26.47 47.8629 26.8636 48.7844 27.3573C49.5954 27.7904 50.3587 28.3025 51.065 28.8814Z"
                fill="#F9C662"
              />
              <path
                d="M48.7843 27.3572L29.8191 46.3215C29.3254 45.4 28.9328 44.4179 28.6543 43.3874L45.8503 26.1914C46.8806 26.4699 47.8628 26.8635 48.7843 27.3572Z"
                fill="#FFD488"
              />
              <path
                d="M43.138 38.8413V33.5815C44.5305 33.906 45.5495 34.9203 45.5495 36.1187C45.5495 36.6462 45.977 37.0738 46.5046 37.0738C47.0322 37.0738 47.4597 36.6462 47.4597 36.1187C47.4597 33.8878 45.593 32.0277 43.1378 31.6384V31.0766C43.1378 30.5492 42.7104 30.1216 42.1828 30.1216C41.6552 30.1216 41.2277 30.5492 41.2277 31.0766V31.6384C38.7725 32.0278 36.9058 33.888 36.9058 36.1187C36.9058 38.3495 38.7725 40.2098 41.2277 40.5991V45.8589C39.8351 45.5344 38.816 44.5201 38.816 43.3216C38.816 42.7941 38.3886 42.3666 37.8609 42.3666C37.3333 42.3666 36.9059 42.7941 36.9059 43.3216C36.9059 45.5525 38.7727 47.4127 41.2278 47.802V48.3637C41.2278 48.8912 41.6553 49.3188 42.1829 49.3188C42.7105 49.3188 43.138 48.8912 43.138 48.3637V47.802C45.5931 47.4127 47.4598 45.5524 47.4598 43.3216C47.4598 41.0908 45.5931 39.2307 43.138 38.8413ZM38.8161 36.1186C38.8161 34.9202 39.8351 33.9059 41.2278 33.5813V38.6559C39.8353 38.3315 38.8161 37.3171 38.8161 36.1186ZM43.138 45.8589V40.7844C44.5305 41.1089 45.5495 42.1232 45.5495 43.3216C45.5495 44.5201 44.5305 45.5344 43.138 45.8589Z"
                fill="#E0A33F"
              />
              <path
                d="M42.1828 54.6885C37.6819 54.6885 33.4617 52.6907 30.604 49.207C28.4183 46.5431 27.2144 43.1738 27.2144 39.72C27.2144 31.4663 33.9292 24.7515 42.1828 24.7515C45.6357 24.7515 49.0053 25.956 51.6709 28.1429C55.1538 30.9998 57.1512 35.2194 57.1512 39.7198C57.1511 47.9736 50.4365 54.6885 42.1828 54.6885ZM42.1828 26.6617C34.9825 26.6617 29.1246 32.5196 29.1246 39.72C29.1246 42.733 30.1745 45.6721 32.0809 47.9954C34.5742 51.0349 38.2561 52.7782 42.1828 52.7782C49.3831 52.7782 55.241 46.9203 55.241 39.72C55.241 35.7939 53.4981 32.1125 50.4592 29.6199C48.1345 27.7122 45.195 26.6617 42.1828 26.6617Z"
                fill="#E0A33F"
              />
            </svg>
          </div>
          <div className="text-center space-y-6 py-8 pb-10 border-b border-gray-200">
            <svg
              className="mx-auto"
              width="208"
              height="202"
              viewBox="0 0 208 202"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M175.02 25.1734C181.856 25.1734 187.397 19.6375 187.397 12.8194C187.397 5.98909 181.856 0.453125 175.02 0.453125C168.183 0.453125 162.643 5.98909 162.643 12.8194C162.643 19.6375 168.183 25.1734 175.02 25.1734Z"
                fill="#FE76A8"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M60.9378 109.999L46.5455 87.9273C-2.55547 49.4866 -22.4284 120.892 35.1636 114.062L60.9378 109.999Z"
                fill="#FE76A8"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M82.0594 87.9386C118.147 42.5962 46.9188 21.938 53.8271 83.8765L65.8326 104.547L82.0594 87.9386Z"
                fill="#FE76A8"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M88.2477 201.547L97.1349 161.309L88.2477 123.204H70.0896L47.8657 121.287L43.416 138.063V193.663C43.416 198.001 46.9662 201.547 51.3078 201.547H88.2477Z"
                fill="#99E6FC"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M162.606 193.662V142.352V127.614C162.606 125.193 160.615 123.204 158.193 123.204H117.775L107.209 162.388L117.775 201.547H154.715C159.056 201.547 162.606 198 162.606 193.662Z"
                fill="#99E6FC"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M82.0583 87.9384C106.825 56.8193 81.039 37.3233 64.668 48.1799C78.1604 48.9348 89.8421 65.0877 73.1115 88.3101L75.0785 91.8209L82.0583 87.9384Z"
                fill="#FE5694"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M46.5465 87.927C18.5299 65.9988 0.0361562 79.8146 0 94.4697C5.82887 86.0339 19.4293 82.0197 37.8633 93.9306C38.6068 92.7801 39.6264 91.7856 40.9098 91.0787L46.5465 87.927Z"
                fill="#FE5694"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M88.2471 123.204H117.775V201.547H88.2471V123.204Z"
                fill="#FE76A8"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M162.607 142.352V127.614C162.607 125.193 160.616 123.204 158.193 123.204H140.383C142.806 123.204 144.797 125.193 144.797 127.614V142.352V193.662C144.797 198 141.235 201.547 136.893 201.547H154.715C159.057 201.547 162.607 198 162.607 193.662V142.352H162.607Z"
                fill="#2ED1E2"
              />
              <path
                d="M36.5078 101.023C36.1568 101.023 35.8001 100.963 35.4512 100.837C31.0035 99.2197 27.4992 98.2625 20.688 97.5175C18.9907 97.3318 17.7651 95.8055 17.9507 94.1086C18.1364 92.4121 19.6623 91.1881 21.3612 91.373C27.9392 92.0928 32.0281 93.015 37.5649 95.0276C39.1696 95.6109 39.9975 97.3842 39.4141 98.9885C38.9575 100.244 37.7712 101.023 36.5078 101.023Z"
                fill="#FE0263"
              />
              <path
                d="M67.623 84.0921C67.5409 84.0921 67.4584 84.0888 67.3751 84.0823C65.6725 83.9475 64.4018 82.459 64.5367 80.7572C64.9356 75.7266 67.2951 68.6777 70.1474 63.9961C71.0355 62.5385 72.9379 62.0753 74.3964 62.9638C75.8552 63.8515 76.3175 65.7531 75.4295 67.2108C73.0992 71.036 71.0224 77.2009 70.7019 81.2455C70.5739 82.8636 69.2195 84.0921 67.623 84.0921Z"
                fill="#FE0263"
              />
              <path
                d="M139.855 108.572C139.105 108.572 138.354 108.301 137.759 107.753C136.503 106.596 136.425 104.641 137.582 103.386C150.821 89.0425 146.897 51.254 146.856 50.8737C146.672 49.1768 147.899 47.6521 149.597 47.4681C151.296 47.2861 152.82 48.5114 153.004 50.2079C153.185 51.8735 157.252 91.1916 142.128 107.577C141.519 108.237 140.688 108.572 139.855 108.572Z"
                fill="#E0D2CE"
              />
              <path
                d="M171.196 65.504C170.968 65.504 170.736 65.4785 170.504 65.4256C168.842 65.045 167.801 63.394 168.178 61.732C168.247 61.4318 169.909 54.2867 175.139 46.2811C182.106 35.6199 192.151 28.4667 204.189 25.5949C205.851 25.1988 207.518 26.2229 207.915 27.8833C208.311 29.5436 207.286 31.2109 205.625 31.607C195.078 34.1233 186.604 40.1358 180.439 49.4771C175.753 56.5763 174.223 63.0418 174.208 63.1059C173.877 64.5347 172.604 65.504 171.196 65.504Z"
                fill="#E0D2CE"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M137.528 27.378L143.597 20.0687C144.844 18.6545 144.76 18.2353 142.925 17.7799L133.499 15.4431C132.431 15.1795 132.371 14.8919 131.844 14.029L127.082 6.08436C126.123 4.46667 125.535 4.46667 124.564 6.08436L119.802 14.029C119.287 14.8919 119.214 15.1795 118.147 15.4431L108.721 17.7799C106.898 18.2353 106.801 18.6545 108.049 20.0687L114.13 27.378C114.777 28.1567 114.873 29.0675 114.79 30.1701L114.058 39.2051C113.854 41.0743 114.25 41.3022 116.001 40.5953L124.289 37.1442C125.152 36.7847 126.507 36.7847 127.359 37.1442L135.658 40.5953C137.409 41.3022 137.793 41.0747 137.589 39.2051L136.87 30.1701C136.785 29.0675 136.868 28.1567 137.528 27.378Z"
                fill="#FECE85"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M126.171 19.841C124.935 27.7495 122.309 34.6395 119.131 39.289L124.288 37.144C125.152 36.7845 126.507 36.7845 127.359 37.144L135.658 40.5951C137.409 41.302 137.793 41.0745 137.589 39.2049L136.869 30.1699C136.785 29.0674 136.869 28.157 137.529 27.3778L143.598 20.0685C144.845 18.6544 144.761 18.2351 142.926 17.7797L133.5 15.4429C132.432 15.1793 132.372 14.8917 131.844 14.0288L127.083 6.08417C126.927 5.82051 126.783 5.60479 126.651 5.43701C127.106 9.73961 126.986 14.6768 126.171 19.841Z"
                fill="#FDB440"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M197.998 98.4234L204.067 91.1142C205.326 89.7004 205.23 89.2808 203.395 88.8254L193.969 86.4886C192.913 86.225 192.841 85.9373 192.325 85.0749L187.564 77.1303C186.593 75.5126 186.005 75.5126 185.045 77.1303L180.272 85.0749C179.756 85.9378 179.684 86.2254 178.629 86.4886L169.202 88.8254C167.367 89.2808 167.271 89.7 168.531 91.1142L174.599 98.4234C175.259 99.2022 175.343 100.113 175.259 101.216L174.527 110.238C174.335 112.12 174.719 112.347 176.47 111.64L184.758 108.189C185.621 107.83 186.977 107.83 187.84 108.189L196.128 111.64C197.878 112.347 198.262 112.12 198.071 110.238L197.339 101.216C197.255 100.113 197.339 99.2026 197.998 98.4234Z"
                fill="#FECE85"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M186.654 90.8862C185.406 98.7946 182.792 105.685 179.602 110.334L184.759 108.189C185.623 107.83 186.978 107.83 187.841 108.189L196.129 111.64C197.88 112.347 198.263 112.12 198.072 110.238L197.34 101.215C197.256 100.113 197.34 99.2025 198 98.4233L204.068 91.1141C205.328 89.7003 205.232 89.2807 203.397 88.8253L193.97 86.4885C192.915 86.2249 192.843 85.9372 192.327 85.0748L187.566 77.1301C187.41 76.8665 187.266 76.6508 187.122 76.4712C187.589 80.7848 187.457 85.7219 186.654 90.8862Z"
                fill="#FDB440"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M187.396 12.8194C187.396 5.98909 181.855 0.453125 175.019 0.453125C172.764 0.453125 170.653 1.05234 168.831 2.10656C172.536 4.23937 175.019 8.24175 175.019 12.819C175.019 17.3844 172.536 21.3868 168.831 23.5196C170.653 24.5742 172.764 25.173 175.019 25.173C181.855 25.1734 187.396 19.6375 187.396 12.8194Z"
                fill="#FE5694"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M41.7737 110.371L61.9704 106.536L75.0793 91.8214L70.8697 84.2842C70.0783 82.8583 68.9388 81.7557 67.6193 81.0009C65.0766 79.551 61.8384 79.4193 59.1039 80.9412L53.8267 83.8771L46.5467 87.9274L40.91 91.0787C38.2954 92.5286 36.7362 95.153 36.5083 97.933C36.3645 99.5746 36.7 101.276 37.5637 102.822L41.7737 110.371Z"
                fill="#FECE85"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M87.2519 113.642L122.584 93.9663C125.499 92.3368 126.554 88.6338 124.935 85.7222L118.662 74.4825C117.031 71.5709 113.325 70.5163 110.411 72.1458L82.0588 87.9391L75.0786 91.8217V106.536L87.2519 113.642Z"
                fill="#01D0FB"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.6131 151.879L53.9455 132.191L54.953 117.476L41.7722 110.371L35.1637 114.061L6.42761 130.058C3.52536 131.676 2.46992 135.379 4.08883 138.291L10.3613 149.542C11.9811 152.442 15.6991 153.496 18.6131 151.879Z"
                fill="#01D0FB"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M73.1115 88.3105L70.8686 84.2842C70.0773 82.8583 68.9377 81.7557 67.6182 81.0009C65.0755 79.551 61.8373 79.4193 59.1028 80.9412L53.8256 83.8771L48.8125 86.6693C51.547 85.1475 54.7734 85.2791 57.3279 86.729C58.647 87.4838 59.7865 88.5982 60.5783 90.0123L64.7879 97.5613L75.0782 91.8218L73.1115 88.3105Z"
                fill="#FDB440"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M122.584 93.9663C125.499 92.3368 126.554 88.6338 124.935 85.7222L118.662 74.4825C117.031 71.5709 113.325 70.5163 110.411 72.1458L96.2349 80.0424C99.1493 78.413 102.855 79.4672 104.474 82.3792L110.747 93.6189C112.366 96.5309 111.323 100.221 108.432 101.851L122.584 93.9663Z"
                fill="#00B1CD"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M87.2523 113.642L75.079 91.8213L41.7734 110.371L53.9467 132.191C65.0646 125.996 76.1345 119.837 87.2523 113.642Z"
                fill="#FE5694"
              />
            </svg>
            <p className="text-3xl xl:text-40 font-bold">
              <span>
                {intl.formatMessage({ id: "REFER TO YOUR FRIENDS AND EARN" })}
              </span>
            </p>
            <h1 className="text-[#E58F0D]">
              <span>{intl.formatMessage({ id: "REFER AND GET 10 COIN" })}</span>
            </h1>
            <p className="text-base font-normal">
              <span>
                {intl.formatMessage({
                  id: "REFER A FRIEND AND GET ADDITIONAL 10 COINS AND YOUR FRIEND GETS ADDITIONAL 20 POINT. SO REFER AWAY",
                })}
              </span>
            </p>
          </div>
          <div className="pt-10 space-y-5 max-[1096px]:flex max-[1096px]:items-center max-[1096px]:flex-col">
            <h3 className="text-lg">
              <span>
                {intl.formatMessage({ id: "SHARE YOUR REFER CODE VIA" })}
              </span>
            </h3>
            <div className="flex items-center justify-between max-[1096px]:flex-col">
              <div className="flex items-center space-x-5 max-[768px]:space-x-2">
                <div className="w-2/12 mb-5">
                  <WhatsappShareButton url={url}>
                    <WhatsappIcon size={size} round={true} />
                  </WhatsappShareButton>
                </div>
                <div className="w-2/12 mb-5">
                  <FacebookShareButton url={url}>
                    <FacebookIcon size={size} round={true} />
                  </FacebookShareButton>
                </div>
                <div className="w-2/12 mb-5">
                  <TelegramShareButton url={url}>
                    <TelegramIcon size={size} round={true} />
                  </TelegramShareButton>
                </div>
                <div className="w-2/12 mb-5">
                  <TwitterShareButton url={url}>
                    <TwitterIcon size={size} round={true} />
                  </TwitterShareButton>
                </div>
                <div className="w-2/12 mb-5">
                  <EmailShareButton url={url}>
                    <EmailIcon size={size} round={true} />
                  </EmailShareButton>
                </div>
                {/* <div className="w-2/12 mb-5">
                                    <LinkedinShareButton url={url}>
                                        <LinkedinIcon size={size} round={true} />
                                    </LinkedinShareButton>
                                </div> */}
                <img
                  src={copy}
                  style={{ width: "50px", height: "50px" }}
                  alt="copy"
                  onClick={() => copyCode()}
                  className="cope-event mb-5"
                />
                {/* <button
                  type="button"
                  className="flex p-3 px-4 bg-brightGray rounded-md text-lg font-bold uppercase max-[768px]:rounded-full max-[768px]:py-[0.45rem] max-[768px]:px-3 mb-5"
                >
                  <i className="icon-share mr-2 max-[768px]:mr-0"></i> <span className="max-[768px]:hidden">{intl.formatMessage({ id: "OTHER" })}</span>
                </button> */}
                {/* <div className="flex items-center space-x-2 listShare"> */}
                {/* <!-- whatsapp  --> */}
                {/* <a href="#">
                    <svg
                      width="55"
                      height="55"
                      viewBox="0 0 60 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="30" cy="30" r="30" fill="#00E676" />
                      <path
                        d="M30.0037 15H29.9963C21.7256 15 15 21.7275 15 30C15 33.2812 16.0575 36.3225 17.8556 38.7919L15.9863 44.3644L21.7519 42.5213C24.1237 44.0925 26.9531 45 30.0037 45C38.2744 45 45 38.2706 45 30C45 21.7294 38.2744 15 30.0037 15ZM38.7319 36.1819C38.37 37.2038 36.9338 38.0512 35.7881 38.2987C35.0044 38.4656 33.9806 38.5988 30.5344 37.17C26.1262 35.3438 23.2875 30.8644 23.0662 30.5737C22.8544 30.2831 21.285 28.2019 21.285 26.0494C21.285 23.8969 22.3781 22.8488 22.8188 22.3988C23.1806 22.0294 23.7787 21.8606 24.3525 21.8606C24.5381 21.8606 24.705 21.87 24.855 21.8775C25.2956 21.8962 25.5169 21.9225 25.8075 22.6181C26.1694 23.49 27.0506 25.6425 27.1556 25.8638C27.2625 26.085 27.3694 26.385 27.2194 26.6756C27.0788 26.9756 26.955 27.1087 26.7337 27.3637C26.5125 27.6187 26.3025 27.8138 26.0812 28.0875C25.8787 28.3256 25.65 28.5806 25.905 29.0213C26.16 29.4525 27.0413 30.8906 28.3387 32.0456C30.0131 33.5362 31.3706 34.0125 31.8562 34.215C32.2181 34.365 32.6494 34.3294 32.9137 34.0481C33.2494 33.6863 33.6637 33.0863 34.0856 32.4956C34.3856 32.0719 34.7644 32.0194 35.1619 32.1694C35.5669 32.31 37.71 33.3694 38.1506 33.5887C38.5912 33.81 38.8819 33.915 38.9887 34.1006C39.0938 34.2863 39.0938 35.1581 38.7319 36.1819Z"
                        fill="#EEEEEE"
                      />
                    </svg>
                  </a> */}
                {/* <!-- facebook  --> */}
                {/* <a href="#">
                    <svg
                      width="60"
                      height="60"
                      viewBox="0 0 60 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="30" cy="30" r="30" fill="#3C5897" />
                      <path
                        d="M31.875 25.3125V21.5625C31.875 20.5275 32.715 19.6875 33.75 19.6875H35.625V15H31.875C28.7681 15 26.25 17.5181 26.25 20.625V25.3125H22.5V30H26.25V45H31.875V30H35.625L37.5 25.3125H31.875Z"
                        fill="#EEEEEE"
                      />
                    </svg>
                  </a> */}
                {/* <!-- massage  --> */}
                {/* <a href="#">
                    <svg
                      width="60"
                      height="60"
                      viewBox="0 0 60 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="30" cy="30" r="30" fill="#2EA4E1" />
                      <g clipPath="url(#clip0_1_1382)">
                        <path
                          d="M23.771 33.9763L23.2747 40.9563C23.9847 40.9563 24.2922 40.6513 24.661 40.285L27.9897 37.1038L34.8872 42.155C36.1522 42.86 37.0435 42.4888 37.3847 40.9913L41.9122 19.7763L41.9135 19.775C42.3147 17.905 41.2372 17.1738 40.0047 17.6325L13.3922 27.8213C11.576 28.5263 11.6035 29.5388 13.0835 29.9975L19.8872 32.1138L35.691 22.225C36.4347 21.7325 37.111 22.005 36.5547 22.4975L23.771 33.9763Z"
                          fill="#EEEEEE"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_1382">
                          <rect
                            width="30"
                            height="30"
                            fill="white"
                            transform="translate(12 15)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </a> */}
                {/* <!-- twitter  --> */}
                {/* <a href="#">
                    <svg
                      width="60"
                      height="60"
                      viewBox="0 0 60 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="30" cy="30" r="30" fill="#00ACEE" />
                      <g clipPath="url(#clip0_1_1386)">
                        <path
                          d="M45 21.5082C43.9216 21.9813 42.7724 22.2948 41.5744 22.4471C42.8069 21.7112 43.7476 20.5548 44.1898 19.161C43.0407 19.8461 41.7719 20.3301 40.4198 20.6001C39.3287 19.4383 37.7736 18.7188 36.0771 18.7188C32.7856 18.7188 30.1357 21.3904 30.1357 24.6656C30.1357 25.1368 30.1756 25.5899 30.2734 26.0213C25.3308 25.7802 20.9572 23.4113 18.0191 19.8026C17.5062 20.6926 17.2053 21.7112 17.2053 22.8078C17.2053 24.8668 18.2656 26.6919 19.8461 27.7486C18.8909 27.7305 17.9539 27.4532 17.16 27.0164C17.16 27.0345 17.16 27.0581 17.16 27.0816C17.16 29.9707 19.2208 32.3705 21.9232 32.9233C21.4393 33.0556 20.9119 33.1191 20.3645 33.1191C19.9839 33.1191 19.5996 33.0973 19.2389 33.0176C20.0092 35.372 22.1951 37.1029 24.7943 37.1591C22.7715 38.7414 20.2032 39.6948 17.4228 39.6948C16.9353 39.6948 16.4676 39.6731 16 39.6133C18.6336 41.3116 21.7547 42.2812 25.1205 42.2812C36.0607 42.2812 42.042 33.2188 42.042 25.3634C42.042 25.1006 42.0329 24.8468 42.0203 24.5949C43.2002 23.7575 44.1916 22.7117 45 21.5082Z"
                          fill="#EEEEEE"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_1386">
                          <rect
                            width="29"
                            height="29"
                            fill="white"
                            transform="translate(16 16)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </a> */}
                {/* <!-- massage  --> */}
                {/* <a href="#">
                    <svg
                      width="60"
                      height="60"
                      viewBox="0 0 60 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="30" cy="30" r="30" fill="#333333" />
                      <path
                        d="M40.5 18H20.5C19.1187 18 18.0125 19.1187 18.0125 20.5L18 43L23 38H40.5C41.8812 38 43 36.8813 43 35.5V20.5C43 19.1187 41.8812 18 40.5 18ZM26.75 29.25H24.25V26.75H26.75V29.25ZM31.75 29.25H29.25V26.75H31.75V29.25ZM36.75 29.25H34.25V26.75H36.75V29.25Z"
                        fill="#EEEEEE"
                      />
                    </svg>
                  </a> */}
                {/* </div> */}
              </div>
              <div className="max-[1096px]:mt-6">
                <div className="rounded-md border-2 border-dotted border-ufoGreen bg-green-100 p-3 flex items-center justify-between">
                  <div className="pr-7">
                    <p className="text-xs">
                      <span>{intl.formatMessage({ id: "REFERRAL CODE" })}</span>
                    </p>

                    <h1 className="text-40 text-ufoGreen">
                      {profileDetails.my_refer_code}
                    </h1>
                  </div>
                  <div className="px-3 border-l-2 border-ufoGreen border-dotted">
                    {/* <a href="#" className="text-sm font-bold uppercase">Copy<br />Code</a> */}
                    <button
                      className="text-sm font-bold uppercase"
                      onClick={() => copyCode()}
                    >
                      <span>{intl.formatMessage({ id: "COPY" })}</span>
                      <br />
                      <span>{intl.formatMessage({ id: "CODE" })}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- advisement --> */}
      </div>
    </div>
  );
};

export default ReferToEarn;
